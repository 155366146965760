import { BASE_URL } from 'shared/config/axiosConfig';
import APIs from 'shared/config/apis';
import { getAccessToken, getLoogedInAs } from 'shared/utils/utils';

export const getTransformedUrl = (template: string, variables: any = {}) => {
  // String with dynamic variables using a custom syntax, e.g., {{variable}}
  const regex = /:([^:/]*)/g;
  return template.replace(regex, (match, p1) => variables[p1.trim()] as string);
};

export const stringToColor = (str: string) => {
  if (!str) return '';
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${(
    (hash >> 16) &
    0xff
  )
    .toString(16)
    .padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color;
};

export const getInitials = (name: string, initialsLength = 2): string => {
  const nameParts = name.split(' ');

  // If there are multiple words, take the first letter of each word
  if (nameParts.length > 1) {
    const initials = nameParts
      .map(part => part.charAt(0).toUpperCase())
      .join('');
    return initials.slice(0, initialsLength);
  }

  // If it's a single word, return the first N characters of the word
  return name.substring(0, initialsLength).toUpperCase();
};
