import React, { useState, useEffect, Fragment } from 'react';
import Styles from '../ManageGroup.module.scss';
import { Button, Table, ColomnProps } from 'design-web';
import vectorBlueImg from '../../../assets/images/vector-blue.svg';
import searchImg from '../../../assets/images/search.svg';
import { useTheme } from 'shared/src/hooks/useTheme';
import trashRed from '../../../assets/images/trashRed.svg';
import Modal from 'packages/web/src/components/Modal';
import { GroupForm } from './GroupForm';
import useGroupService from 'shared/hooks/service/useGroupService';
import { useGroupContext } from 'shared/hooks/context/useGroupContext';
import { Group } from 'shared/contexts/GroupContext';
import useDebounce from 'shared/hooks/useDebounce';
import DeleteModal from '../../../components/DeleteModal';
import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';
import noRecords from '../../../assets/images/noRecord.svg';
import UserActionMenu from 'packages/web/src/components/UserActionMenu';

export const GroupList: React.FC = () => {
  const { isMobile } = useTheme();
  const { groups: data, setGroups } = useGroupContext();
  const { getGroupList, loading, deleteGroup } = useGroupService();
  // toggle delete modal
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  // set selected user
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { debounceVal, setCurrentValue } = useDebounce(null, 300);

  const fetchData = async () => {
    try {
      const queryPayload = { searchTerm: debounceVal, pageSize: 1000 };
      const result = await getGroupList(queryPayload);
      setGroups(result || []);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [debounceVal]);

  const loadMoreRecords = () => {
    if (!loading) fetchData();
  };

  const handleDeleteGroup = async () => {
    if (!selectedGroup?.id) return;
    try {
      await deleteGroup(selectedGroup.id);
      setGroups(data.filter(item => item.id !== selectedGroup.id));
      setIsDeleteModalVisible(false);
      setSelectedGroup(null);
    } catch (error) {
      console.error(error);
    }
  };

  const columns: ColomnProps[] = [
    { key: 'group', title: 'Group', textAlign: 'left' },
    { key: 'action', title: 'Action', textAlign: 'left' },
  ];

  const handleAddClick = () => {
    setSelectedGroup(null); // Reset selected user when adding a new gropu
    setIsModalVisible(true);
  };

  const handleEditClick = (group: Group) => {
    setSelectedGroup(group); // Set selected user when editing
    setIsModalVisible(true);
  };

  const handleSearchGroups = (searchText: string) => {
    // fetch data with search term
    setCurrentValue(searchText);
  };

  const tableData = data?.map((item, index) => ({
    group: (
      <div className="flex items-center gap-x-3 py-2">
        {/* <img
          src={item.avatar}
          className="w-10 h-10 lg:w-12 lg:h-12 rounded-full"
          alt={item.name}
        /> */}
        <div className="max-w-xs break-all">
          {' '}
          {/* Add max width and break styles */}
          <Button
            type="button"
            className={`text-brandRed bg-brandRed-light hover:bg-brandRed hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-semiBase lg:text-base px-8 py-1 text-center my-4 md:mb-2 
            inline-block max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer`}
            title={item.name}
          >
            {item.name}
          </Button>
        </div>
      </div>
    ),
    action: (
      <UserActionMenu
        item={item}
        handleEditClick={handleEditClick}
        setSelectedUser={setSelectedGroup}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    ),
  }));

  const AddGroupButton: React.FC<{
    className: string;
  }> = ({ className }) => (
    <Button type="button" className={className} onClick={handleAddClick}>
      <span
        className={`${isMobile ? 'text-xl' : 'text-base md:text-2xl'} mr-1`}
      >
        +
      </span>{' '}
      Add Groups
    </Button>
  );

  return (
    <>
      <div className="max-w-screen-xl mx-auto px-4 py-4 container">
        <div className="items-center justify-between md:flex">
          <div className="max-w-lg flex items-center justify-between">
            <h3 className="font-inter text-xl font-semibold leading-[29.26px] text-left mb-8 md:mb-0">
              Manage Group
            </h3>
            {isMobile && (
              <AddGroupButton
                className="py-1 px-3 mb-8 text-semiBase md:text-xl font-bold
text-white focus:outline-none rounded-lg border
border-gray-200 bg-primary-lightBlue hover:bg-blue-500 focus:z-10 
focus:ring-4 focus:ring-gray-200 text-center"
              />
            )}
          </div>

          <div className="flex items-center">
            <div className="relative flex items-center w-full md:w-auto mr-0 sm:mr-4 mb-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <img src={`${searchImg}`} alt="Search image" className="" />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full text-[15px] font-normal text-left py-3 pl-10 px-6 text-gray-900 border 
        border-gray-300 rounded-xl bg-white focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search for Groups"
                onChange={e => handleSearchGroups(e.target.value)}
              />
            </div>
            {!isMobile && (
              <AddGroupButton
                className="py-2 px-10 mr-2 mb-2 text-semiBase md:text-xl font-normal
text-white focus:outline-none rounded-xl border
border-gray-200 bg-primary-lightBlue hover:bg-blue-500 focus:z-10 
focus:ring-4 focus:ring-gray-200 text-center"
              />
            )}
          </div>
        </div>
        <div className="shadow-md border rounded-2xl overflow-x-auto bg-white mt-4">
          {isMobile ? (
            <div className="flex flex-col md:gap-4">
              {loading ? ( // Conditionally show loader when loading is true
                <div className="flex justify-center items-center py-4">
                  {<DataSaveLoader visible={true} />}
                </div>
              ) : data.length === 0 ? ( // Check if there is no data
                <div className="flex justify-center items-center py-16">
                  <div>
                    <img
                      src={`${noRecords}`}
                      className="w-16 h-16"
                      alt="Empty state"
                    />
                    <p className="text-gray-500">No records</p>
                  </div>
                </div>
              ) : (
                <div>
                  {data.map((item, idx) => (
                    <div key={idx} className="bg-white shadow-md p-4 border">
                      <div className="flex items-center space-between">
                        <div className="flex items-center gap-x-3">
                          {/* <img
                        src={item.avatar}
                        className="w-10 h-10 rounded-full"
                        alt={item.name}
                      /> */}
                          <div className="break-all">
                            <Button
                              type="button"
                              className={`text-brandRed bg-brandRed-light hover:bg-brandRed hover:text-white focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-semiBase px-5 py-1 text-center my-4 md:mb-2 
                          inline-block max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer`}
                              title={item.name}
                            >
                              {item.name}
                            </Button>
                          </div>
                        </div>
                        <div className="ml-auto">
                          <div className="p-2">
                            <UserActionMenu
                              item={item}
                              handleEditClick={handleEditClick}
                              setSelectedUser={setSelectedGroup}
                              setIsDeleteModalVisible={setIsDeleteModalVisible}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className={`mx-auto mt-8 ${Styles.table}`}>
              <Table
                data={tableData}
                coloums={columns}
                exportAndSearchBar={false}
                loadingData={loading}
                // loadMoreRecords={loadMoreRecords}
                infiniteScrolling={true}
              />
            </div>
          )}
        </div>
      </div>

      {/* DeleteModal Component */}
      <DeleteModal
        title="Delete Group"
        isOpen={isDeleteModalVisible}
        loading={loading}
        onClose={() => setIsDeleteModalVisible(false)}
        onDelete={handleDeleteGroup}
        body={'Are you sure you want to delete this group?'}
        primaryBtnTxt="Delete"
      />

      <GroupForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        editGroup={selectedGroup}
      />
    </>
  );
};
