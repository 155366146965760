export const UPDATE_PROGRESS = '@file-upload/UPDATE_PROGRESS';
export const RESET = '@file-upload/RESET';
export const START_UPLOAD = '@file-upload/START_UPLOAD';
export const ADD_FILE_IN_QUEUE = '@file-upload/ADD_FILE_IN_QUEUE';
export const UPDATE_FILE_STATE = '@file-upload/UPDATE_FILE_STATE';
export const UPDATE_FILES_STORE = '@file-upload/UPDATE_FILES_STORE';
export const ADD_FILE_TO_UPLOADED_STACK =
  '@file-upload/ADD_FILE_TO_UPLOADED_STACK';
export const DELETE_FILE_IN_PROGRESS = '@file-upload/DELETE_FILE_IN_PROGRESS';
export const SET_DUPLICATE_FILE_RESPONSE =
  '@file-upload/SET_DUPLICATE_FILE_RESPONSE';
