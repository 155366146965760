import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import done from '../../../assets/images/done.svg';
import threeDot from '../../../assets/images/dotAction.svg';
import { DocumentVersion } from 'shared/contexts/DocumentContext';
import useAccessControl from 'shared/hooks/useAccessControl';
import { ActionPermissions } from 'shared/types/types';
import { useDocumentContext } from 'shared/hooks/context/useDocumentContext';
import crossIcon from '../../../assets/images/crossIcon.svg';
import DeleteModal from 'packages/web/src/components/DeleteModal';
import UserProfileImage from 'packages/web/src/components/UserProfileImage';
import CustomDropdown from 'packages/web/src/components/CustomDropdown';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';

dayjs.extend(relativeTime);

export const UserCard: React.FC<{
  docVersion: DocumentVersion;
  className?: string;
  activeDocVersion: string;
  onDocVersionView: (docVersion: DocumentVersion) => void;
  onDownload: (docVersion: DocumentVersion) => void;
  onDelete: (docVersion: DocumentVersion) => void;
}> = ({
  docVersion,
  activeDocVersion,
  onDocVersionView,
  onDownload,
  onDelete,
}) => {
  const { document } = useDocumentContext();
  const [canEdit, setCanEdit] = useState(
    useAccessControl(ActionPermissions.EDIT)
  );
  const { owner: user } = docVersion;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [docAction, setDocAction] = useState(canEdit ? 'edit' : 'view');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false); // State for delete modal
  const { userInfo } = useAuthContext();
  const canDownload = useAccessControl(ActionPermissions.DOWNLOAD);

  // Handler for deleting the user
  const handleDelete = () => {
    console.log(`Deleting user ${user?.email}`);
    setDeleteModalOpen(false);
    // Add delete logic here
    onDelete(docVersion);
  };

  const handleDocVersionClick = () => {
    if (activeDocVersion !== docVersion.fileMetaData.path) {
      onDocVersionView(docVersion);
    }
  };

  useEffect(() => {
    if (!document) return;
    const canUserEdit = canEdit || userInfo?.id === document?.userId;
    setCanEdit(canUserEdit);
    setDocAction(canUserEdit ? 'edit' : 'view');
  }, [document]);

  // Define action items for the dropdown
  const actionItems = [
    ...(canDownload && document
      ? [
          {
            label: 'Download',
            action: (e: Event) => {
              onDownload(docVersion);
              setDropdownOpen(false);
              e.stopPropagation();
            },
          },
        ]
      : []),
    ...(canEdit && document?.fileMetaData.path !== docVersion.fileMetaData.path
      ? [
          {
            label: 'Restore this version',
            action: (e: Event) => {
              setDropdownOpen(false);
              setDeleteModalOpen(true); // Open delete modal
              e.stopPropagation();
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <div
        className={`flex mt-2 items-start py-6 px-2 mb-2 rounded-lg hover:bg-gray-100 ${
          activeDocVersion === docVersion.fileMetaData.path
            ? 'bg-gray-100'
            : 'bg-white cursor-pointer'
        }`}
        onClick={handleDocVersionClick}
      >
        <div className="flex flex-col">
          <div className="flex items-center">
            <div className="flex-1">
              <div className="flex items-center justify-between">
                <div className="flex flex-row">
                  <div>
                    <UserProfileImage
                      user={user}
                      imgClassName={'w-12 h-12 mr-3 rounded-full'}
                      avatarClassName={'w-12 h-12'}
                      initialsLength={2}
                    />
                  </div>
                  <div>
                    <h4 className="text-gray-800 text-base font-semibold">
                      {user?.email?.split('@')[0] || 'U N'}
                    </h4>
                    <p className="text-gray-500 text-sm">
                      {dayjs(docVersion.createdAt).fromNow()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-gray-800 text-semiBase font-medium mt-3">
            {dayjs(docVersion.createdAt).format('MMMM D, YYYY h:mm A')}
          </p>
        </div>
        <div
          className={`space-x-4 flex items-center ml-auto right-0 relative ${
            user.profileImg ? 'ml-4' : ''
          }`}
        >
          {actionItems.length ? (
            <div className="absolute pl-2 z-1000 -left-4 cursor-pointer">
              <CustomDropdown
                trigger={
                  <img
                    src={isDropdownOpen ? crossIcon : threeDot}
                    alt="dot action"
                    className="w-4 h-4"
                    onClick={e => {
                      setDropdownOpen(prev => !prev);
                      e.stopPropagation();
                    }} // Toggle dropdown
                  />
                }
                items={actionItems}
              />
            </div>
          ) : null}
          <img src={done} alt="done" />
        </div>
      </div>
      <hr />

      {/* Delete Modal */}
      {isDeleteModalOpen && (
        <DeleteModal
          title="Restore this version?"
          isOpen={isDeleteModalOpen}
          loading={false}
          onClose={() => setDeleteModalOpen(false)}
          onDelete={handleDelete}
          body={
            <div>
              Your current document will revert to the version from{' '}
              {dayjs(docVersion.createdAt).format('MMMM D, YYYY h:mm A')}.
            </div>
          }
          primaryBtnTxt="Restore"
          secondaryBtnTxt="Cancel"
        />
      )}
    </>
  );
};
