import React, { ReactNode } from 'react';
import Modal from 'packages/web/src/components/Modal';
import DataSaveLoader from './DataSaveLoader';

interface DeleteModalProps {
  isOpen: boolean;
  loading?: boolean | undefined;
  onClose: () => void;
  onDelete: () => void;
  title?: string;
  body?: ReactNode | string;
  secondaryBtnTxt?: string;
  primaryBtnTxt?: string;
  secondaryBtnClassName?: string;
  primaryBtnClassName?: string;
  primaryBtnDisabled?: string;
  secondaryBtnDisabled?: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  loading = false,
  onClose,
  onDelete,
  title = 'Remove User',
  body = 'Are you sure you want to remove this User?',
  secondaryBtnTxt = 'Discard',
  primaryBtnTxt = 'Remove',
  secondaryBtnClassName = 'btn btn-outline-danger',
  primaryBtnClassName = 'btn btn-primary',
  primaryBtnDisabled = false,
  secondaryBtnDisabled = false,
}) => {
  return (
    <Modal
      title={title}
      loading={loading}
      onClose={onClose}
      isOpen={isOpen}
      className="max-w-md"
    >
      {<DataSaveLoader visible={loading} />}
      <div className="p-5">
        <p className="text-base">{body}</p>
        <div className="mt-10 flex items-center justify-end">
          <button
            type="button"
            className="btn btn-outline-danger text-base"
            onClick={onClose}
          >
            {secondaryBtnTxt}
          </button>
          <button
            type="button"
            className="btn btn-primary ltr:ml-4 rtl:mr-4 text-base"
            onClick={onDelete}
          >
            {primaryBtnTxt}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
