import { Box, Paper, Typography } from '../../../components/material';

// third-party
import { FileRejection } from 'react-dropzone';

// utils
import getDropzoneData from '../../../utils/getDropzoneData';

// ==============================|| DROPZONE - REJECTION FILES ||============================== //

type Props = {
  fileRejections: FileRejection[];
};

export default function RejectionFiles({ fileRejections }: Props) {
  return (
    <Paper variant="outlined">
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = getDropzoneData(file);

        return (
          <Box key={path}>
            <Typography variant="caption">
              {path} - {size ? size : ''}
            </Typography>

            {errors.map(error => (
              <Box key={error.code}>{error.message}</Box>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}
