export enum JobStatus {
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum DocSharePermissions {
  VIEWER = 'Viewer',
  EDITOR = 'Editor',
  OWNER = 'OWNER',
  NONE = 'None',
}

export enum DocShareAccessType {
  RESTRICTED = 'RESTRICTED',
  PUBLIC = 'PUBLIC',
}

export enum ActionPermissions {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  SHARE = 'SHARE',
  CREATE = 'ADD',
  DOWNLOAD = 'DOWNLOAD',
}

// export enum type of permissions
export type PermissionTypes = 'Viewer' | 'Editor' | 'None';

// permissions.ts
export type Permission =
  | 'VIEW'
  | 'ADD'
  | 'EDIT'
  | 'DELETE'
  | 'SHARE'
  | 'DOWNLOAD';
