import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { Field, Form } from 'formik';

import { ReactNode } from 'react';

import styles from './Dropdown.module.scss';
import colorCodes from 'shared/assets/styles/colors.module.scss';

export interface DropdownOptionProps {
  value: string;
  label: string;
}

interface DropdownProps {
  label?: string; // Make label prop optional
  name?: string;
  children?: ReactNode; // Make children prop optional
  options: DropdownOptionProps[];
  onSelect: (
    _selectedOption: DropdownOptionProps | DropdownOptionProps[]
  ) => void;
  placement: string;
  isRequired?: boolean;
  style?: React.CSSProperties;
  value?: DropdownOptionProps | DropdownOptionProps[];
  isSearchable?: boolean;
  className?: string;
  classNamePrefix?: string;
  isMulti: boolean;
  form?: typeof Form;
  field?: typeof Field;
}

const requiredFieldAsteriskSymbol = '*';

const Dropdown: React.FC<DropdownProps> = ({
  name,
  options,
  onSelect,
  placement,
  label,
  isRequired = false,
  isSearchable = false,
  value,
  className,
  classNamePrefix,
  isMulti = false,
}) => {
  const handleOptionSelect = (
    option: DropdownOptionProps | DropdownOptionProps[]
  ) => {
    onSelect(option);
  };

  const selectStyles: StylesConfig<DropdownOptionProps> = {
    control: styles => ({
      ...styles,
      minWidth: 220,
      height: 50,
      borderRadius: '10px',
      borderColor: '#d1d5db',
      '&:hover': {
        borderColor: '#60a5fa',
      },
      fontSize: 16,
      fontWeight: 600,
      color: 'green',
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isFocused || isSelected ? 'white' : 'black',
      };
    },
  };

  return (
    <div>
      {label && (
        <label htmlFor={label} className={styles.DropdownLabel}>
          {label}
          {isRequired && (
            <span className={styles.DropdownLabelRequiredAsterisk}>
              {requiredFieldAsteriskSymbol}
            </span>
          )}
        </label>
      )}

      <Select
        className={`dropdown-toggle ${className}`}
        classNamePrefix={classNamePrefix}
        name={name}
        placeholder={placement}
        options={options}
        onChange={option =>
          handleOptionSelect(
            option as DropdownOptionProps | DropdownOptionProps[]
          )
        }
        styles={selectStyles}
        value={value}
        isSearchable={isSearchable}
        isMulti={isMulti}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: colorCodes.colorPrimary,
            primary: colorCodes.colorPrimary,
            primary50: colorCodes.colorPrimary,
            primary75: colorCodes.colorPrimary,
          },
        })}
      />
    </div>
  );
};

export default Dropdown;
