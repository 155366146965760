import { components } from 'react-select';

const commonStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0.675rem', // Tailwind `rounded-md`
    borderColor: state.isFocused ? '#2563EB' : '#D1D5DB', // Tailwind `focus:ring-blue-500` and `border-gray-300`
    boxShadow: state.isFocused ? '0 0 0 1px #2563EB' : 'none', // Tailwind `focus:ring-2`
    '&:hover': {
      borderColor: '#2563EB', // Tailwind `hover:border-blue-500`
    },
    minHeight: '50px',
    marginTop: '9px',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    borderRadius: '0.375rem', // Tailwind `rounded-md`
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    fontSize: '0.9rem', // Tailwind `text-base`
    fontWeight: '550', // Tailwind `font-medium`
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#2563EB'
      : state.isFocused
      ? '#316EFD'
      : '#FFFFFF', // Tailwind `bg-blue-500`, `hover:bg-blue-100`, and `bg-white`
    color: state.isSelected ? '#FFFFFF' : state.isFocused ? 'white' : 'black', // Tailwind `text-white` and `text-gray-900`
    fontSize: '1rem', // Tailwind `text-base`
    fontWeight: '600', // Tailwind `font-medium`
    '&:hover': {
      backgroundColor: '#316EFD', // Tailwind `hover:bg-blue-100`
    },
    whiteSpace: 'nowrap', // Prevents text from breaking into a new line
    overflow: 'hidden', // Hides overflow text
    textOverflow: 'ellipsis', // Adds ellipsis (...) for overflow text
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '0.375rem', // Tailwind `rounded-md`
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Tailwind `shadow-md`
    maxHeight: 160, // Set the fixed height for the dropdown menu
    overflowY: 'auto', // Make the menu scrollable if content exceeds height
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '0',
    maxHeight: 160, // Set the fixed height for the dropdown menu
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 1000, // Ensure the dropdown is above the modal
  }),
};

// Custom styles for the Groups multi-select
export const groupCustomStyles = (hasError: boolean) => ({
  ...commonStyles,
  control: (provided: any, state: any) => ({
    ...commonStyles.control(provided, state),
    borderColor: hasError ? '#F87171' : state.isFocused ? '#2563EB' : '#D1D5DB',
  }),

  multiValue: (provided: any) => ({
    ...commonStyles.multiValue(provided),
    backgroundColor: '#FEE2E2', // Light red for group values
  }),
  multiValueLabel: (provided: any) => ({
    ...commonStyles.multiValueLabel(provided),
    color: '#F00044',
  }),
  multiValueRemove: (provided: any) => ({
    ...commonStyles.multiValueRemove(provided),
    color: '#EF4444', // Tailwind `text-red-500`
    '&:hover': {
      backgroundColor: '#ECC1C2', // Tailwind `hover:bg-red-100`
      color: '#B91C1C', // Tailwind `hover:text-red-700`
    },
  }),
});

// Custom styles for the Roles multi-select
export const roleCustomStyles = (hasError: boolean) => ({
  ...commonStyles,
  control: (provided: any, state: any) => ({
    ...commonStyles.control(provided, state),
    borderColor: hasError ? '#F87171' : state.isFocused ? '#2563EB' : '#D1D5DB',
  }),
  multiValue: (provided: any) => ({
    ...commonStyles.multiValue(provided),
    backgroundColor: '#316EFD',
  }),
  multiValueLabel: (provided: any) => ({
    ...commonStyles.multiValueLabel(provided),
    color: 'white',
  }),
  multiValueRemove: (provided: any) => ({
    ...commonStyles.multiValueRemove(provided),
    color: 'white',
    '&:hover': {
      backgroundColor: '#AAC6FF',
      color: 'blue',
      opacity: '0.8',
    },
  }),
});

// Custom styles for the Permissions multi-select
export const permissionCustomStyles = (hasError: boolean) => ({
  ...commonStyles,
  control: (provided: any, state: any) => ({
    ...commonStyles.control(provided, state),
    borderColor: hasError ? '#F87171' : state.isFocused ? '#2563EB' : '#D1D5DB',
  }),
  multiValue: (provided: any) => ({
    ...commonStyles.multiValue(provided),
    backgroundColor: '#e5e5e5',
  }),
  multiValueLabel: (provided: any) => ({
    ...commonStyles.multiValueLabel(provided),
    color: 'black',
  }),
  multiValueRemove: (provided: any) => ({
    ...commonStyles.multiValueRemove(provided),
    color: 'black', // Tailwind `text-red-500`
    '&:hover': {
      backgroundColor: '#ECC1C2', // Tailwind `hover:bg-red-100`
      color: '#B91C1C', // Tailwind `hover:text-red-700`
    },
  }),
});

export const MultiSelectContainer = (props: any) => {
  const { children, selectProps } = props;
  const selectedValues = selectProps.value || [];

  return (
    <components.ValueContainer {...props}>
      {selectedValues.length > props.MAX_DISPLAYED ? (
        <>
          {children[0].slice(0, props.MAX_DISPLAYED)}{' '}
          {/* Show only the first two selected items */}
          <span
            // Conditionally apply onClick or onTouchStart based on isMobile flag
            {...(props.isMobile
              ? { onTouchStart: props.onShowMoreClick }
              : { onClick: props.onShowMoreClick })}
            className={props.showMoreClassName}
            style={{ cursor: 'pointer', display: 'inline-block' }} // Ensure clickable style
          >
            +{selectedValues.length - props.MAX_DISPLAYED}
          </span>
        </>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};
