import React from 'react';
import CustomSelect from './CustomSelect';
import { ActionPermissions, PermissionTypes } from 'shared/types/types';
import useAccessControl from 'shared/hooks/useAccessControl';
import { Document } from 'shared/src/contexts/DocumentContext';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';

const UserPermissionSelect: React.FC<{
  id: string;
  permission: PermissionTypes;
  onChange: (id: string, permission: PermissionTypes) => void;
  document: Document;
}> = ({ id, permission, onChange, document }) => {
  const canEdit = useAccessControl(ActionPermissions.EDIT);
  const { userInfo } = useAuthContext();

  const options = [
    // { value: 'Editor', label: 'Editor' },
    ...(canEdit || userInfo?.id === document.user.id || userInfo?.isSuperAdmin
      ? [{ value: 'Editor', label: 'Editor' }]
      : []),
    { value: 'Viewer', label: 'Viewer' },
    { value: 'None', label: 'Remove Access', customClass: 'text-red-600' },
  ];

  return (
    <CustomSelect
      value={permission}
      options={options}
      onChange={value => onChange(id, value as PermissionTypes)}
      className="w-24 sm:w-28"
    />
  );
};

export default UserPermissionSelect;
