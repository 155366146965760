import React from 'react';

interface DeleteProps {
  fillColor: string;
  borderColor: string;
  height: number;
  width: number;
  className?: string;
}

export const Delete: React.FC<DeleteProps> = ({
  fillColor,
  borderColor,
  height,
  width,
  className,
}: DeleteProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.47692 1.14517H6.47736C6.46399 1.14054 6.45009 1.13753 6.436 1.13614H5.37174V0.253477C5.3718 0.187144 5.3458 0.12346 5.29945 0.0760839C5.25304 0.0287123 5.18995 0.00138376 5.12369 0H2.60672C2.53948 0 2.47501 0.026666 2.42752 0.0742178C2.37997 0.12177 2.35324 0.186236 2.35324 0.253475V1.13614H1.29439C1.2803 1.13753 1.2664 1.14054 1.25303 1.14517H0.253471C0.162881 1.14517 0.0792062 1.19345 0.0339488 1.27188C-0.0113163 1.35031 -0.0113163 1.44691 0.0339488 1.52535C0.0792139 1.60378 0.162882 1.65211 0.253471 1.65211H1.06783L1.72585 7.7735C1.7329 7.8358 1.76263 7.89328 1.8094 7.93511C1.85611 7.97689 1.91661 7.99994 1.97932 8H5.75462C5.81668 7.9991 5.87627 7.97556 5.92233 7.93391C5.96831 7.89219 5.99751 7.83519 6.00449 7.7735L6.66245 1.66105H7.47687C7.56746 1.66105 7.65114 1.61277 7.69639 1.53434C7.74165 1.45591 7.74166 1.35924 7.69639 1.28087C7.65113 1.20244 7.56746 1.1541 7.47687 1.1541L7.47692 1.14517ZM2.86566 0.506945H4.86472V1.13614H2.86566V0.506945ZM5.52455 7.48941H2.20582L1.57663 1.66111H6.15368L5.52455 7.48941Z"
        fill={fillColor}
      />
      <path
        d="M3.86577 6.89668C3.93301 6.89668 3.99748 6.86995 4.04503 6.82246C4.09258 6.77491 4.11925 6.71044 4.11925 6.6432V2.5084C4.11925 2.41781 4.07097 2.33413 3.99254 2.28888C3.91411 2.24355 3.81745 2.24355 3.73901 2.28888C3.66058 2.33414 3.6123 2.41781 3.6123 2.5084V6.6432C3.6123 6.71044 3.63897 6.77491 3.68652 6.82246C3.73407 6.86995 3.79854 6.89668 3.86578 6.89668H3.86577Z"
        fill={fillColor}
      />
      <path
        d="M2.8674 6.89444H2.87817C2.94511 6.89167 3.00819 6.86248 3.0537 6.8133C3.09915 6.76412 3.12334 6.69893 3.12088 6.63199L2.96805 2.49719L2.96811 2.49713C2.96672 2.42941 2.93777 2.36512 2.88787 2.31926C2.83791 2.27345 2.77146 2.25004 2.7038 2.25443C2.63687 2.25726 2.57379 2.28645 2.52834 2.33563C2.48283 2.38481 2.45869 2.45 2.4611 2.51694L2.61393 6.65174C2.61712 6.71693 2.64517 6.77845 2.69236 6.82359C2.73949 6.86867 2.80215 6.89407 2.8674 6.89443L2.8674 6.89444Z"
        fill={fillColor}
      />
      <path
        d="M4.85421 6.89408H4.86318C4.92999 6.89559 4.9947 6.87079 5.04345 6.8251C5.09215 6.77936 5.12104 6.71633 5.12387 6.64957L5.2767 2.51477H5.27664C5.27911 2.44783 5.25491 2.38265 5.20946 2.33346C5.16396 2.28429 5.10088 2.25509 5.03394 2.25226C4.9655 2.24522 4.89724 2.26731 4.84589 2.31318C4.79455 2.35899 4.76493 2.42435 4.76427 2.49322L4.60964 6.62803H4.6097C4.60627 6.69586 4.63022 6.76226 4.67621 6.81228C4.72214 6.8623 4.7863 6.89179 4.85421 6.89408L4.85421 6.89408Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default Delete;
