import axios, {
  AxiosRequestConfig,
  AxiosResponse as ServiceResponse,
} from 'axios';
import { getAccessToken, getLoogedInAs } from './utils';

export const BASE_URL = `${
  process.env.NX_PUBLIC_API_URL || 'http://localhost:3001'
}`;

const axiosService = axios.create({ baseURL: BASE_URL });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

/**
 * Intercepts and modifies the configuration object for an HTTP request.
 * This interceptor is responsible for adding or removing the 'Authorization' header based on the request URL.
 *
 * @param {Object} config - The configuration object for the HTTP request.
 * @param {string} config.url - The URL of the HTTP request.
 * @param {Object} config.headers - The headers of the HTTP request.
 * @returns {Object} The modified configuration object.
 */
axiosService.interceptors.request.use(
  async (config: any) => {
    const accessToken = getAccessToken();
    const loggedInAs = getLoogedInAs();

    // Check if config URL is a third-party URL
    try {
      new URL(config.url || '');
      delete config.headers['Authorization'];
      console.log('interceptors Authorization deleted ==>');
    } catch (error) {
      // If the URL is not a third-party URL and an access token is available, add the 'Authorization' header
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
        config.headers['X-Logged-In-As'] = loggedInAs;
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosService.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      window.location.pathname = '/logout';
    }
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
      return Promise.reject(error);
    } else {
      return Promise.reject(
        (error.response && error.response.data) || 'Wrong Services'
      );
    }
  }
);

export default axiosService;

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosService.get(url, { ...config });

  return res.data;
};

export const fetcherPost = async (
  args: string | [string, AxiosRequestConfig]
) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosService.post(url, { ...config });

  return res.data;
};

export const cancelRequest = (abortController: AbortController) => {
  abortController.abort();
};

export const getAbortController = () => new AbortController();
export type { ServiceResponse };
