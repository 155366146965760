/**
 * UploadDocument Component
 *
 * This component is responsible for rendering the document upload interface. It includes breadcrumb navigation,
 * a document upload section, and a confirmation dialog for navigation actions during the upload process.
 */

import { BreadcrumbNav } from 'design-web';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Styles from './UploadDocument.module.scss';
import { useEffect, useState } from 'react';
import { useCallbackPrompt } from 'shared/hooks/useCallBackPrompt';
import useFileUpload from 'shared/hooks/context/useFileUpload';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { DropzonePage } from './components/DropZone';
import useAccessControl from 'shared/hooks/useAccessControl';
import { ActionPermissions } from 'shared/types/types';
import routes from 'shared/config/routes';
import { getTransformedUrl } from 'shared/utils/utils';
import { useGroupContext } from 'shared/hooks/context/useGroupContext';
import { JobStatus } from 'shared/types/types';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import { useDocumentContext } from 'shared/hooks/context/useDocumentContext';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';

const UploadDocument: React.FC = () => {
  // State to manage the visibility of the confirmation modal
  const [isConfirmModalVisible, setIsConfirmationModalVisible] =
    useState(false);

  // State to manage the visibility of the dialog
  const [showDialog, setShowDialog] = useState<boolean>(false);

  // Custom hook to manage prompt for navigation
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const { updateGroupType } = useGroupContext();
  // Custom hook to manage file upload process
  const { isUploading, resetFileContext, stopFileUploadProcess, jobStatus } =
    useFileUpload();

  const canCreate = useAccessControl(ActionPermissions.CREATE);
  const { document } = useDocumentContext();
  const { userInfo } = useAuthContext();

  const { groupId, folderId } = useParams();

  const navigate = useNavigate();
  const { getDocumentDetail } = useDocumentService();

  useEffect(() => {
    if (groupId && folderId) {
      getDocumentDetail('', folderId);
    }
  }, []);

  useEffect(() => {
    updateGroupType(null);
    if (jobStatus === JobStatus.COMPLETED && !showDialog) {
      handleConfirmNavigation();
      setTimeout(() => {
        if (!folderId) {
          navigate(
            getTransformedUrl(routes.groupDocuments, { groupId: groupId })
          );
        } else {
          navigate(
            getTransformedUrl(routes.groupFoldersDocuments, {
              groupId: groupId,
              folderId: folderId,
            })
          );
        }
      }, 100);
    }
  }, [jobStatus, showDialog]);

  // Effect to show dialog when uploading is in progress
  useEffect(() => {
    setShowDialog(isUploading);
  }, [isUploading]);

  // Handler to confirm navigation and stop the file upload process
  const handleConfirmNavigation = () => {
    stopFileUploadProcess();
    resetFileContext();
    if (typeof confirmNavigation === 'function') {
      confirmNavigation();
    }
  };

  const navigateToDashboard = () => {
    return (
      <Navigate
        to={getTransformedUrl(routes.allGroups, { groupType: 'all' })}
      />
    );
  };

  useEffect(() => {
    if (canCreate) return;
    if (!canCreate && !folderId) {
      navigateToDashboard();
    }
    if (!document) return;
    // if canView is false then navigate to home page
    if (document.userId !== userInfo?.id) {
      navigateToDashboard();
    }
  }, [document]);

  return (
    <div
      className={`max-w-screen-xl mx-auto px-4 py-4 ${Styles['document-upload-container']}`}
    >
      <div className="w-full">
        <BreadcrumbNav className="font-inter text-sm font-bold text-gray-500 leading-[15px] text-left mb-6" />
      </div>
      <div className="items-center justify-between md:flex">
        <div className="max-w-lg flex items-center justify-between">
          <h3 className="font-montserrat text-2xl font-semibold leading-[29.26px] text-left mb-8 md:mb-0">
            Upload Document
          </h3>
        </div>
      </div>
      <div
        className={`shadow-md border rounded-2xl overflow-x-auto bg-white mt-4 ${Styles['document-list-container']}`}
      >
        <DropzonePage />
        <ConfirmationDialog
          showDialog={showPrompt}
          confirmNavigation={handleConfirmNavigation}
          cancelNavigation={cancelNavigation}
          setIsConfirmationModalVisible={setIsConfirmationModalVisible}
        />
      </div>
    </div>
  );
};

export default UploadDocument;
