import React from 'react';
import Select from 'react-select';
import { PermissionTypes } from 'shared/types/types';

interface CustomSelectProps {
  value: string;
  options: { value: string; label: string; customClass?: string }[];
  onChange: (value: PermissionTypes) => void;
  className?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  options,
  onChange,
  className,
}) => {
  // Convert options to react-select format
  const formattedOptions = options.map(option => ({
    value: option.value,
    label: option.label,
    className: option.customClass,
  }));

  // Handle change in react-select
  const handleSelectChange = (selectedOption: any) => {
    onChange(selectedOption.value as PermissionTypes);
  };

  return (
    <Select
      value={formattedOptions.find(option => option.value === value)}
      onChange={handleSelectChange}
      options={formattedOptions}
      className={className}
      classNamePrefix="react-select"
      styles={{
        control: provided => ({
          ...provided,
          minWidth: '80px', // Adjust width as needed
          // border: '1px solid #D1D5DB',
          borderRadius: '0.375rem',
          boxShadow: 'none', // Remove any shadows
          display: 'flex',
        }),
        valueContainer: provided => ({
          ...provided,
          padding: '0 8px', // Adjust padding to your liking
        }),
        indicatorsContainer: provided => ({
          ...provided,
          borderLeft: 'none', // Remove the left border that can appear as |
          padding: '0',
        }),
        dropdownIndicator: provided => ({
          ...provided,
          padding: '4px', // Adjust padding
        }),
        indicatorSeparator: provided => ({
          display: 'none', // Hide the | separator
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'white' : 'black',
          backgroundColor: state.isSelected ? '#3B82F6' : 'white',
        }),
        menu: provided => ({
          ...provided,
          zIndex: 9999, // Ensure the dropdown menu appears on top
        }),
        menuPortal: provided => ({
          ...provided,
          zIndex: 9999, // Increase z-index for the portal
        }),
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary25: '#E5E7EB', // Option hover background color
          primary: '#3B82F6', // Option selected background color
        },
      })}
      menuPortalTarget={document.body} // Attach the menu to the body to prevent clipping
    />
  );
};

export default CustomSelect;
