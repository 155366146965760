import { useState, useEffect } from 'react';
import useDebounce from './useDebounce';

const useWindowSize = () => {
  const { debounceVal, setCurrentValue } = useDebounce(null, 300);
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    // Debounce to avoid the function fire multiple times
    const handleResizeDebounced = () => {
      setCurrentValue(getSize()); // Clear search input when window size changes to avoid unnecessary re-renders
    };
    window.addEventListener('resize', handleResizeDebounced);
    return () => window.removeEventListener('resize', handleResizeDebounced);
  }, []);

  useEffect(() => {
    setWindowSize(debounceVal);
  }, [debounceVal]);

  return windowSize;
};

export default useWindowSize;
