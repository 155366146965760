import { Formik } from 'formik';
import * as yup from 'yup';
import MultiFileUpload from './MultiFile';
import { Stack } from '../../../components/material';
import FormHelperText from '../../../components/FormHelperText';
import { useEffect, useState } from 'react';
import useFileUpload from 'shared/hooks/context/useFileUpload';

export const DropzonePage: React.FC = () => {
  const { isUploading, resetFileContext } = useFileUpload();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileSubmission = (values: File[]) => {
    console.log(values);
    setSelectedFiles(values);
    // TODO: Upload files to server
    // Example:
    // const formData = new FormData();
    // values.forEach((file) => formData.append('files', file));
    // fetch('/upload', { method: 'POST', body: formData });
  };

  useEffect(() => {
    const handleTabClose = (event: any) => {
      if (!isUploading) return true;
      event.preventDefault();

      console.log('beforeunload event triggered');

      return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', handleTabClose);
    resetFileContext();
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={{ files: null }}
      onSubmit={(values: any) => {
        // submit form
        handleFileSubmission(values);
      }}
      validationSchema={yup.object().shape({
        files: yup.mixed().required('File is a required.'),
      })}
    >
      {({ values, handleSubmit, setFieldValue, touched, errors }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <div>
              <Stack spacing={1.5} className="flex justify-center">
                <MultiFileUpload
                  setFieldValue={setFieldValue}
                  files={values.files}
                  error={touched.files && !!errors.files}
                />
              </Stack>
              {touched.files && errors.files && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-login"
                >
                  {errors.files as string}
                </FormHelperText>
              )}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
