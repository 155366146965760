import React, { useState, useEffect } from 'react';
import { Document } from 'shared/src/contexts/DocumentContext';
import Modal from 'packages/web/src/components/Modal';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import { Button } from 'design-web';
import UserListItem from './share/components/UserListItem';
import CustomSelect from './share/components/CustomSelect';
import {
  ActionPermissions,
  DocShareAccessType,
  DocSharePermissions,
  PermissionTypes,
} from 'shared/types/types';
import UserMessageInput from './share/components/UserMessageInput';
import EmailSelect from './share/components/EmailSelect';
import useDebounce from 'shared/hooks/useDebounce';
import useUserService from 'shared/hooks/service/useUserService';
import useDocumentService, {
  SharedUser,
} from 'shared/hooks/service/useDocumentService';
import { ScrollArea } from '@mantine/core';
import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';
import { useTheme } from 'shared/hooks/useTheme';
import { getUserFullName } from 'shared/utils/utils';
import UserProfileImage from 'packages/web/src/components/UserProfileImage';
import useAccessControl from 'shared/hooks/useAccessControl';
import { showToast } from 'design-web';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  document: Document;
  title: string;
  loading: boolean;
}

const ShareModal: React.FC<ShareModalProps> = ({
  isOpen,
  onClose,
  document,
  title,
  loading = false,
}) => {
  const { userInfo } = useAuthContext();
  const { isMobile } = useTheme();
  const [tempSelectedUsers, setTempSelectedUsers] = useState<SharedUser[]>([]);
  const [sharedUsers, setSharedUsers] = useState<SharedUser[]>([]);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [sharedUsersLoading, setSharedUsersLoading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<
    { label: string; value: string; permission?: string }[]
  >([]);
  const [message, setMessage] = useState('');
  const [permissionLevel, setPermissionLevel] = useState<PermissionTypes>(
    DocSharePermissions.VIEWER
  );
  const [userPermissions, setUserPermissions] = useState<
    Map<string, PermissionTypes>
  >(new Map());

  const { debounceVal, setCurrentValue } = useDebounce(null, 300);

  const { getUserListToShare, data: usersData } = useUserService();
  const { shareDocument, getSharedDocumentUsers } = useDocumentService();
  const [canEdit, setCanEdit] = useState(
    useAccessControl(ActionPermissions.EDIT)
  );

  useEffect(() => {
    if (isOpen) {
      fetchSharedUserList();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!document) return;
    setCanEdit(canEdit || document.userId === userInfo?.id);
  }, [document]);

  useEffect(() => {
    if (selectedEmails.length === 0) {
      setMessage('');
      setTempSelectedUsers([]);
    }
  }, [selectedEmails]);

  useEffect(() => {
    fetchUsersListOnSearch();
  }, [debounceVal]);

  const fetchUsersListOnSearch = async () => {
    const queryPayload = {
      groupId: document.groupId,
      searchTerm: debounceVal,
    };
    getUserListToShare(queryPayload, 'refresh');
  };

  const fetchSharedUserList = async () => {
    setSharedUsersLoading(true); // Start loading
    try {
      const sharedUsers = await getSharedDocumentUsers(document.id);
      // Filter out the current user from the shared users
      const filteredUsers = sharedUsers.filter(
        user => user.userId !== userInfo?.id
      );
      setSharedUsers(filteredUsers);
      const permissionsMap = new Map<string, PermissionTypes>();
      filteredUsers.forEach(user => {
        permissionsMap.set(user.userId, user.permission || 'None');
      });
      setUserPermissions(permissionsMap);
      await fetchUsersListOnSearch();
    } catch (error) {
      console.error('Error fetching already shared users', error);
    } finally {
      setSharedUsersLoading(false); // End loading
    }
  };

  const handleEmailChange = (selectedOptions: any) => {
    const selectedUsersFromOptions = selectedOptions.map((option: any) => {
      const existingUser = usersData.find(user => user.email === option.value);
      return (
        existingUser || {
          id: Math.random(),
          name: '',
          email: option.value,
          permission: permissionLevel,
        }
      );
    });

    setSelectedEmails(selectedOptions);
    setTempSelectedUsers(prevUsers => {
      const updatedUsers = [...prevUsers];
      selectedUsersFromOptions.forEach((user: SharedUser) => {
        const index = updatedUsers.findIndex(u => u.email === user.email);
        if (index >= 0) {
          updatedUsers[index] = {
            ...updatedUsers[index],
            permission: user.permission || permissionLevel,
          };
        } else {
          updatedUsers.push({ ...user, permission: permissionLevel });
        }
      });
      return updatedUsers;
    });

    setUserPermissions(prev => {
      const updatedPermissions = new Map(prev);
      selectedUsersFromOptions.forEach((user: any) => {
        updatedPermissions.set(user.userId, user.permission || permissionLevel);
      });
      return updatedPermissions;
    });
  };

  const handleInputChange = (searchText: any) => {
    setCurrentValue(searchText);
  };

  const handlePermissionChange = (id: string, permission: PermissionTypes) => {
    setUserPermissions(prev => new Map(prev).set(id, permission));
  };

  const handleRemoveAccess = (email: string) => {
    setTempSelectedUsers(prevUsers =>
      prevUsers.filter(user => user.email !== email)
    );
    setUserPermissions(prev => {
      const updatedPermissions = new Map(prev);
      updatedPermissions.delete(email);
      return updatedPermissions;
    });
  };

  const handleSubmit = async () => {
    setIsSubmitInProgress(true);
    const shareAction = tempSelectedUsers.length ? 'SHARE' : 'SHARE_PATCH';
    const permissionsMap = new Map(userPermissions);
    console.log(userPermissions, 'userPermissions');
    let userActivePermissions = [];
    if (shareAction === 'SHARE') {
      userActivePermissions = tempSelectedUsers.map(user => {
        return { userId: user.id, action: permissionLevel };
      });
    } else {
      userActivePermissions = Array.from(permissionsMap).map((user: any) => {
        return { userId: user[0], action: user[1] };
      });
    }

    // share doc with the selected users
    const requestPayload = {
      userPermissions: userActivePermissions,
      message: message,
      accessType: DocShareAccessType.RESTRICTED,
    };
    try {
      await shareDocument(document.id, requestPayload, shareAction);
      const docType = document?.isFolder ? 'Folder' : 'Document';
      const actionType = selectedEmails?.length ? 'shared' : 'updated';
      showToast('success', `${docType} ${actionType} successfully`);
      setIsSubmitInProgress(false);
      handleClose();
      onClose();
    } catch (err) {
      console.log('err');
    }
  };

  const handleClose = () => {
    if (!selectedEmails.length && sharedUsers.length > 0) {
      onClose();
      return;
    }

    setTempSelectedUsers([]);
    setSelectedEmails([]);
    setMessage('');
    setUserPermissions(new Map());
    onClose();
  };

  const CustomButton: React.FC<{
    type: 'submit' | 'button';
    className: string;
    onClick?: () => void;
    label: string;
    disabled?: boolean;
  }> = ({ type, className, onClick, label, disabled = false }) => (
    <Button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );

  const filterUserList = () => {
    if (!usersData.length) return [];
    return usersData.filter(
      user =>
        !sharedUsers.find(u => u.userId === user.id) &&
        user.id !== userInfo?.id &&
        document.user.id !== user.id
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      loading={loading || sharedUsersLoading}
      className="max-w-xl"
    >
      {sharedUsersLoading ? ( // Display loader if shared users are being fetched
        <div className="flex justify-center items-center h-full">
          <DataSaveLoader visible={sharedUsersLoading} />
        </div>
      ) : (
        <>
          {<DataSaveLoader visible={isSubmitInProgress} />}
          <div>
            <div className="mb-4">
              <EmailSelect
                users={filterUserList()}
                selectedEmails={selectedEmails}
                onEmailChange={handleEmailChange}
                onInputChange={handleInputChange}
              />
            </div>

            {selectedEmails.length > 0 && (
              <UserMessageInput
                message={message}
                onMessageChange={setMessage}
              />
            )}

            {!selectedEmails.length && (
              <div className="flex flex-wrap items-center mb-2 hover:bg-gray-200 py-2 my-2 sm:m-2 sm:p-2">
                <UserProfileImage
                  user={document?.user}
                  imgClassName={'w-9 h-9 sm:w-11 sm:h-11 mr-2 rounded-full'}
                  avatarClassName={'w-9 h-9 sm:w-11 sm:h-11'}
                  initialsLength={2}
                  fontSize={isMobile ? '13px' : '16px'}
                />
                <div className="flex-1 min-w-0">
                  <p className="font-bold text-sm sm:text-base break-words">
                    {getUserFullName(document?.user)}{' '}
                    {document?.user?.id === userInfo?.id ? '(you)' : ''}
                  </p>
                  <p className="text-sm sm:text-base text-gray-500 break-words">
                    {document?.user?.email}
                  </p>
                </div>
                <div className="flex-shrink-0 ml-2">
                  <p className="text-sm sm:text-base text-gray-500">Owner</p>
                </div>
              </div>
            )}

            {!selectedEmails.length && sharedUsers.length > 0 && (
              <ScrollArea h={250}>
                {sharedUsers.map(
                  user =>
                    userPermissions.get(user.userId) && (
                      <UserListItem
                        key={user.id}
                        user={user}
                        permission={
                          userPermissions.get(user.userId) || permissionLevel
                        }
                        onPermissionChange={handlePermissionChange}
                        onRemoveAccess={handleRemoveAccess}
                        document={document}
                      />
                    )
                )}
              </ScrollArea>
            )}

            {selectedEmails.length > 0 && (
              <div className="mb-4 text-sm sm:text-base flex items-center justify-between hover:bg-gray-100 py-2 my-2 sm:m-1 sm:p-1 sm:py-2">
                <div className="flex items-center">
                  <svg
                    className="w-6 h-6 mr-2 text-gray-400 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Only people invited to this file</span>
                </div>
                <div className="flex items-center">
                  <CustomSelect
                    value={permissionLevel}
                    options={[
                      { value: DocSharePermissions.VIEWER, label: 'Can view' },
                      ...(canEdit ||
                      userInfo?.isSuperAdmin ||
                      userInfo?.id === document?.user.id
                        ? [
                            {
                              value: DocSharePermissions.EDITOR,
                              label: 'Can edit',
                            },
                          ]
                        : []),
                    ]}
                    onChange={setPermissionLevel}
                    className=""
                  />
                </div>
              </div>
            )}

            <div className="mt-2 mb-6 mr-2 flex justify-end pt-12 gap-4">
              {(canEdit ||
                userInfo?.id === document.user.id ||
                selectedEmails.length > 0) && (
                <>
                  <CustomButton
                    type="button"
                    className="w-36 border border-primary rounded-xl py-2 bg-white text-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-gray-400 text-base sm:text-lg font-normal leading-[30px] text-center"
                    onClick={handleClose}
                    label={'Cancel'}
                  />
                  <CustomButton
                    type="submit"
                    className={`w-36 py-2 rounded-xl text-base sm:text-lg font-normal leading-[30px] text-center ${
                      tempSelectedUsers.length === 0 && sharedUsers.length === 0
                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        : 'bg-primary text-white hover:bg-primary-dark'
                    }`}
                    onClick={handleSubmit}
                    label={selectedEmails.length ? 'Share' : 'Update'}
                    disabled={
                      tempSelectedUsers.length === 0 && sharedUsers.length === 0
                    }
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ShareModal;
