import React from 'react';

interface PaperProps {
  children: React.ReactNode;
  elevation?: 'none' | 'sm' | 'md' | 'lg' | 'xl';
  variant?: 'elevation' | 'outlined';
}

export const Paper: React.FC<PaperProps> = ({
  children,
  elevation = 'sm',
  variant = 'elevation',
}) => {
  const className: any = {
    'bg-white': true,
    'rounded-md': true,
    'shadow-none': elevation === 'none',
    'shadow-sm': elevation === 'sm',
    'shadow-md': elevation === 'md',
    'shadow-lg': elevation === 'lg',
    'shadow-xl': elevation === 'xl',
    border: variant === 'outlined',
    'border-gray-300': variant === 'outlined',
  };

  return (
    <div
      className={`p-4 ${Object.keys(className)
        .filter(key => className[key])
        .join(' ')}`}
    >
      {children}
    </div>
  );
};
