/* eslint-disable @typescript-eslint/no-unused-vars */
import APIs from '../../config/apis';
import { useState } from 'react';
import apiClient from '../../config/axiosConfig';
import { User } from '../../contexts/UserContext';

const useUserService = () => {
  const [data, setData] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const createUser = async (userData: any): Promise<User[] | null> => {
    try {
      if (!userData) {
        setError('No user data provided');
        return null;
      }
      setLoading(true);
      const response = await apiClient({
        ...APIs.USER.CREATE,
        data: userData,
      });
      const userRes = response.data?.data;
      if (response.status === 200) {
        setData([userRes.users, ...data]);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      return userRes.users;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async (
    userId: string,
    userData: any
  ): Promise<User | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.USER.UPDATE(userId),
        data: userData,
      });
      const userRes = response.data?.data;
      if (response.status === 200) {
        setData(data.map(item => (item.id === userRes.id ? userRes : item)));
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      return userRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (id: string): Promise<User | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.USER.DELETE(id),
      });
      const userRes = response.data?.data;
      if (response.status === 200) {
        setData(data.filter(item => item.id !== id));
      }
      return userRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getUserList = async (
    queryPayload: any,
    action = ''
  ): Promise<User[] | null> => {
    try {
      setLoading(true);
      const url = APIs.USER.GET_ALL.url;
      // add query payload to url

      const response = await apiClient({
        ...APIs.USER.GET_ALL,
        params: queryPayload,
      });
      let userRes = response.data?.data?.users || [];
      if (userRes) {
        userRes = userRes.map((user: User) => {
          return {
            ...user,
            name: `${user.firstName} ${user.lastName}`,
          };
        });
      }
      if (response.status === 200) {
        if (action === 'refresh') {
          setData([...userRes]);
        } else {
          setData([...data, ...userRes]);
        }
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return userRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getUserListToShare = async (
    queryPayload: any,
    action = ''
  ): Promise<User[] | null> => {
    try {
      setLoading(true);
      const url = APIs.USER.GET_ALL_SHARE.url;
      // add query payload to url

      const response = await apiClient({
        ...APIs.USER.GET_ALL_SHARE,
        params: queryPayload,
      });
      console.log('response user', response);
      let userRes = response.data?.data || [];
      console.log('userRes', userRes);
      if (userRes) {
        userRes = userRes.map((user: User) => {
          return {
            ...user,
            name: `${user.firstName} ${user.lastName}`,
          };
        });
      }
      if (response.status === 200) {
        if (action === 'refresh') {
          setData([...userRes]);
        } else {
          setData([...data, ...userRes]);
        }
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return userRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const updateUserProfile = async (
    userId: string,
    userData: any
  ): Promise<User | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.USER.UPDATE_PROFILE(userId),
        data: userData,
      });
      const userRes = response.data?.data;
      console.log('userRes', userRes);
      if (response.status === 200) {
        setData(data.map(item => (item.id === userRes.id ? userRes : item)));
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      } else if (response.status === 500) {
        throw new Error(response.data.message);
      }

      return userRes; // return updated user
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  // Function to get the pre-signed URL from the backend
  const getPreSignedUrl = async (
    fileName: string,
    fileType: string
  ): Promise<string | null> => {
    try {
      setLoading(true);

      // API request to get pre-signed URL
      const response = await apiClient({
        ...APIs.USER.UPDATE_IMAGE(), // Using the correct API definition
        data: { fileName, contentType: fileType },
      });

      const preSignedUrl = response.data?.signedUrl;
      if (!preSignedUrl) {
        throw new Error('Failed to retrieve pre-signed URL');
      }

      return preSignedUrl;
    } catch (error: any) {
      console.error('Error fetching pre-signed URL:', error);
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Function to upload image to S3 with network error handling
  const uploadImageToS3 = async (
    imageFile: File, // Image file passed as File object
    signedUrl: string
  ): Promise<Response> => {
    try {
      setLoading(true);

      // Determine the correct content type for the image
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: imageFile, // Directly pass the image file
        headers: {
          'Content-Type': imageFile.type, // Ensure correct content type for image
        },
      });

      console.log('response', response);

      if (response.status !== 200) {
        throw new Error('Error in uploading image');
      }
      return response;
    } catch (ex: any) {
      console.error(ex);
      setError(ex?.data);
      throw new Error(ex?.data?.message || 'Image upload failed');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    error,
    createUser,
    updateUser,
    deleteUser,
    getUserList,
    getUserListToShare,
    updateUserProfile,
    getPreSignedUrl,
    uploadImageToS3,
  };
};

export default useUserService;
