import { MsalProvider } from '@azure/msal-react';
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  EventMessage,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { msalAuthConfig, protectedResources } from '../config/msalAuthConfig';
import emitter, { EVENT_AUTH_TOKEN } from '../helpers/emitter';
export const MsalAuthProvider = (props: any) => {
  /**
   * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */
  const msalInstance = new PublicClientApplication(msalAuthConfig);

  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Listen for sign-in event and set active account
  //   msalInstance.addEventCallback(event => {
  //     const authenticationResult = event.payload as AuthenticationResult;
  //     if (
  //       event.eventType === EventType.LOGIN_SUCCESS &&
  //       authenticationResult.account
  //     ) {
  //       const account = authenticationResult.account;
  //       msalInstance.setActiveAccount(account);
  //     }
  //   });

  msalInstance.addEventCallback((event: EventMessage) => {
    if (
      (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.LOGIN_SUCCESS) &&
      event.payload
    ) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      console.log('LOGIN_SUCCESS');
      // set isauthenticated as true
      emitter.emit(EVENT_AUTH_TOKEN, {
        authToken: account.idToken,
        route: null,
        loggedInAs: 'microsoft',
        redirectToPreviousRoute: true,
      });
    } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      if (
        event.error?.name === 'InteractionRequiredAuthError' &&
        window.location.pathname.startsWith(protectedResources.documentApi.path)
      ) {
        handleLoginRedirect(msalInstance);
      } else {
        console.log('ACQUIRE_TOKEN_FAILURE');
      }
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
      if (
        event.error?.name === 'BrowserAuthError' &&
        window.location.pathname.startsWith(protectedResources.documentApi.path)
      ) {
        handleLoginRedirect(msalInstance);
      } else {
        console.log('LOGIN FAILURE');
      }
    } else {
      console.log('Callback finished', event);
    }
  });
  //   await msalInstance
  //     .handleRedirectPromise()
  //     .then(() => {
  //       if (
  //         window.location.pathname.startsWith(protectedResources.documentApi.path)
  //       ) {
  //         const account = msalInstance.getActiveAccount();
  //         if (!account) {
  //             handleLoginRedirect(msalInstance);
  //         }
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });

  return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
};

export function useMsalAuthProvider() {
  // check if user is authenticated or not
  //const { instance } = useMsal();
  //const isAuthenticated = instance.getActiveAccount() !== null;

  //   const checkAuth = async (authProps?: any, emitToken?: any) => {
  //     const currentAccount: AccountInfo | null = instance.getActiveAccount();
  //     if (currentAccount) {
  //       if (currentAccount.tenantId == msalAuthConfig.auth.tenantId) {
  //         const idTokenClaims = currentAccount.idTokenClaims as IdTokenClaims;
  //         if (idTokenClaims && idTokenClaims.aud == msalAuthConfig.auth.clientId && idTokenClaims["roles"]) {
  //           const intersection = authProps.roles.filter((role: any) => idTokenClaims["roles"].includes(role));
  //           if (intersection.length > 0) {
  //             emitToken(currentAccount.idToken || '');
  //           }
  //         //   else {
  //         //     setMessage("You don't have the required role to view this page. Please contact site administrator.");
  //         //   }
  //         }
  //         // else {
  //         //   setMessage("The application you authorized with cannot access this page. Please contact site administrator.");
  //         // }
  //       }
  //     //   else {
  //     //     setMessage("Your organization does not have access this content.");
  //     //   }
  //     }
  //     // if (!isAuthenticated) {
  //     //   loginRedirect();
  //     // }

  //   }

  return {
    MsalAuthProvider,
    handleLoginRedirect,
    //checkAuth
  };
}

export type IdTokenClaims = {
  aud: string;
  roles: string[];
};

const handleLoginRedirect = (
  msalInstance: PublicClientApplication | IPublicClientApplication
) => {
  try {
    const loginRequest = {
      scopes: protectedResources.documentApi.scopes,
    };
    msalInstance.loginRedirect(loginRequest);
  } catch (err) {
    console.log(err);
  }
};
