import { useContext } from 'react';
import FileUploadContext from 'shared/contexts/FileUploadContext';

// ==============================|| AUTH HOOKS ||============================== //

const useFileUpload = () => {
  const context = useContext(FileUploadContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useFileUpload;
