import React, { useState, useEffect } from 'react';
import Delete from 'design-web/components/Icon/assets/filled/Delete';
import FileFilled from 'design-web/components/Icon/assets/filled/FileFilled';

// utils
import getDropzoneData from '../../../utils/getDropzoneData';

// type
import { DropzopType, FilePreviewProps } from '../../../types/dropzone';
import crossUpload from '../../../assets/images/crossUpload.svg';
import useFileUpload from 'shared/hooks/context/useFileUpload';
import { JobStatus } from 'shared/types/types';
import CircularWithLabel from '../../../components/material/CircularWithLabel';
import { useTheme } from 'shared/hooks/useTheme';

export default function FilesPreview({ files, onRemove }: FilePreviewProps) {
  const { filesInProgress, restartUploadProcess } = useFileUpload();
  const { isMobile } = useTheme();

  return (
    <div className="w-full  mx-auto">
      {/* Uploading Files */}
      {files.map((file: any, index: number) => {
        const { key, name } = getDropzoneData(file, index);
        const fileState = filesInProgress[name || ''];

        return (
          <div key={`${file.name}-${index}`}>
            <div className="font-inter text-base font-medium mt-3">
              {fileState?.status === JobStatus.COMPLETED
                ? 'Uploaded'
                : fileState?.status === JobStatus.RUNNING
                ? 'Uploading'
                : 'Ready to upload'}{' '}
              -{' '}
              {files.length === 1
                ? '1 file'
                : `${index + 1}/${files.length} files`}
            </div>
            <div
              key={key}
              className={`relative flex items-center justify-between bg-white border h-10 ${
                fileState?.status === JobStatus.COMPLETED
                  ? 'border-upload-green'
                  : 'border-uploading-input'
              } rounded mt-3 mb-2`}
            >
              <div className="flex items-center">
                <FileFilled
                  height={24}
                  width={24}
                  className="mr-2"
                  fillColor={
                    fileState?.status === JobStatus.RUNNING
                      ? '#E3E3E3'
                      : '#eaf1ff'
                  }
                />
                <span
                  className={`font-inter text-semiBase text-uploaded-text font-medium leading-[16.94px]
                ${
                  isMobile
                    ? 'inline-block max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer'
                    : ''
                }`}
                  title={name}
                >
                  {name}
                </span>
              </div>
              <div className="flex">
                {fileState && fileState?.status !== 'error' && (
                  <CircularWithLabel value={fileState?.progress || 0} />
                )}

                {(!fileState || fileState?.progress <= 99) &&
                  onRemove &&
                  fileState?.status !== JobStatus.COMPLETED && (
                    <button
                      onClick={() => onRemove(file)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <div
                        className="flex items-center justify-center rounded-full border bg-light-svg"
                        style={{ width: '24px', height: '24px' }}
                      >
                        <Delete
                          fillColor="red"
                          borderColor="red"
                          height={20}
                          width={20}
                        />
                      </div>
                    </button>
                  )}
              </div>
              {/* {fileState && fileState?.status !== 'error' && <div
                  className="absolute bottom-0 left-0 h-1 bg-progress-bar rounded-b"
                  style={{ width: `${fileState.progress}%` }}
                ></div>} */}
            </div>
          </div>
        );
      })}
    </div>
  );
}
