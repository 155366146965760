// action - state management

import { FileUploadActionProps, FileUploadProps } from '../../types/fileUpload';
import {
  ADD_FILE_IN_QUEUE,
  RESET,
  START_UPLOAD,
  UPDATE_FILES_STORE,
  UPDATE_FILE_STATE,
  UPDATE_PROGRESS,
  ADD_FILE_TO_UPLOADED_STACK,
  DELETE_FILE_IN_PROGRESS,
  SET_DUPLICATE_FILE_RESPONSE,
} from './actions';
import { JobStatus } from '../../types/types';

// initial state
export const initialState: FileUploadProps = {
  files: [],
  filesInProgress: {},
  isUploading: false,
  jobStatus: JobStatus.PENDING,
  duplicateFiles: [],
};

// ==============================|| AUTH REDUCER ||============================== //

const fileUpload = (state = initialState, action: FileUploadActionProps) => {
  switch (action.type) {
    case UPDATE_PROGRESS: {
      const { fileName, progress } = action.payload!;
      state.filesInProgress[fileName] = progress;
      return {
        ...state,
      };
    }

    case RESET: {
      return { ...initialState, filesInProgress: {} };
    }

    case START_UPLOAD: {
      return {
        ...state,
        files: action.payload.files,
        isUploading: true,
      };
    }

    case SET_DUPLICATE_FILE_RESPONSE: {
      return {
        ...state,
        duplicateFiles: action.payload.files,
      };
    }

    case UPDATE_FILES_STORE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ADD_FILE_IN_QUEUE: {
      const { file } = action.payload;
      const filesInProgress = state.filesInProgress;
      filesInProgress[file.name] = {
        progress: 0,
        file,
        preSignedUploadUrl: null,
        status: 'uploading',
      };
      return {
        ...state,
        filesInProgress,
      };
    }

    case UPDATE_FILE_STATE: {
      const { fileName, ...params } = action.payload;
      const filesInProgress = state.filesInProgress;
      filesInProgress[fileName] = {
        ...filesInProgress[fileName],
        ...params,
      };
      return {
        ...state,
        filesInProgress,
      };
    }

    case DELETE_FILE_IN_PROGRESS: {
      const { file } = action.payload;
      const filesInProgress = state.filesInProgress;
      delete filesInProgress[file.name];
      return {
        ...state,
        filesInProgress,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default fileUpload;
