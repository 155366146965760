import React from 'react';
import Select from 'react-select';
import {
  CustomMultiValue,
  CustomOption,
  customStyles,
} from '../../CustomMultiSelectShare';
import { User } from 'shared/contexts/UserContext';
import { getUserFullName } from 'shared/utils/utils';
import { useTheme } from 'shared/hooks/useTheme';

// type User = {
//   id: number;
//   name: string;
//   email: string;
//   permission?: 'Viewer' | 'Editor' | 'None';
// };

type EmailSelectProps = {
  users: User[];
  selectedEmails: { label: string; value: string; permission?: string }[];
  onEmailChange: (selectedOptions: any) => void;
  onInputChange: (searchString: string) => void;
};

const EmailSelect: React.FC<EmailSelectProps> = ({
  users,
  selectedEmails,
  onEmailChange,
  onInputChange,
}) => {
  const { isMobile } = useTheme();
  return (
    <Select
      isMulti
      components={{ Option: CustomOption, MultiValue: CustomMultiValue }}
      options={users.map(user => ({
        value: user.email,
        label: getUserFullName(user),
        profileImg: user?.profileImg,
      }))}
      value={selectedEmails}
      onChange={onEmailChange}
      placeholder="Add People or Groups"
      className="basic-multi-select"
      classNamePrefix="select"
      styles={customStyles}
      onInputChange={onInputChange}
      {...(!isMobile && {
        menuPortalTarget: document.body,
        menuPosition: 'fixed',
      })}
    />
  );
};

export default EmailSelect;
