// material-ui
// import { CameraOutlined } from '@ant-design/icons';

import { Box } from '@mantine/core';
import { Typography, Stack } from '../../../components/material';
// assets
import UploadCover from '../../../assets/images/UploadCover.svg';
import { DropzopType } from '../../../types/dropzone';

export default function PlaceholderContent({ type }: { type?: string }) {
  return (
    <>
      {type !== DropzopType.standard && (
        <div>
          {/* Placeholder Image */}
          <div className="flex justify-center mb-4 mt-4">
            <img src={UploadCover} alt="Upload" className="w-18" />
          </div>

          {/* Upload Instructions */}
          <Stack spacing={2} direction="column">
            <Typography
              variant="h5"
              className="mt-4 font-inter text-base font-semibold leading-[19.36px] text-center"
            >
              Drag & drop files or{' '}
              <span className="text-progress-bar text-base font-semibold leading-[19.36px] text-center cursor-pointer">
                Browse
              </span>
            </Typography>
          </Stack>

          {/* Supported File Types */}
          <div className="mt-4 px-8 text-uploaded-text font-inter text-semiBase font-medium leading-[16.94px] text-center">
            Supported formats: PDF & Word
          </div>
        </div>
      )}
    </>
  );
}
