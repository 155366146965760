// ManageUser.tsx

import React, { useState } from 'react';
import { GroupList } from './components/';
import GroupProvider from 'shared/src/contexts/GroupContext';

const ManageGroup: React.FC = () => {
  return (
    <GroupProvider>
      {' '}
      <GroupList />
    </GroupProvider>
  );
};

export default ManageGroup;
