import React, { createContext, useState, ReactNode } from 'react';
import { Permission } from '../hooks/service/usePermissionService';

// Define your user type
interface Role {
  id: string;
  name: string;
  rolePermission: any[];
  permissionIds?: string[];
  permissions?: any[];
}

// Define context type
interface RoleContextType {
  role: Role | null;
  roles: Role[];
  setRole: (_role: Role | null) => void;
  setRoles: (_roles: Role[]) => void;
  isLoading: boolean;
}

const RoleContext = createContext<RoleContextType>({
  role: null,
  roles: [],
  setRole: (_role: Role | null) => {},
  setRoles: (_roles: Role[]) => {},
  isLoading: false,
});

// User provider component
const RoleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [role, setRole] = useState<Role | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // CRUD Operations

  // Value object to pass into context provider
  const value: RoleContextType = {
    role,
    roles,
    setRole,
    setRoles,
    isLoading,
  };

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

export { RoleProvider, RoleContext, RoleContextType, Role };
export default RoleProvider;
