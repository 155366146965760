/* eslint-disable @typescript-eslint/no-unused-vars */
import APIs from '../../config/apis';
import { useState } from 'react';
import apiClient from '../../config/axiosConfig';
import { Group } from '../../contexts/GroupContext';
import { useGroupContext } from '../context/useGroupContext';

const useGroupService = () => {
  const [data, setData] = useState<Group[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { updateGroupType } = useGroupContext();

  const createGroup = async (groupData: any): Promise<Group | null> => {
    try {
      if (!groupData) {
        setError('No group data provided');
        return null;
      }
      setLoading(true);
      const response = await apiClient({
        ...APIs.GROUP.CREATE,
        data: groupData,
      });
      const groupRes = response.data?.data;
      if (response.status === 200) {
        setData([...data, groupRes]);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      return groupRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const updateGroup = async (
    groupId: string,
    groupData: any
  ): Promise<Group | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.GROUP.PATCH(groupId),
        data: groupData,
      });

      const groupRes = response.data?.data;
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      console.log(data, 'data');
      const result = [
        ...data.map(item => (item.id === groupRes.id ? groupRes : item)),
      ];
      console.log(result, 'result');
      setData([
        ...data.map(item => (item.id === groupRes.id ? groupRes : item)),
      ]);

      return groupRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const deleteGroup = async (id: string): Promise<Group | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.GROUP.DELETE(id),
      });
      const groupRes = response.data?.data;
      if (response.status === 200) {
        setData(data.filter(item => item.id !== id));
      }
      return groupRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getGroupList = async (
    queryPayload: any = { searchTerm: '' }
  ): Promise<Group[] | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.GROUP.GET_ALL,
        params: queryPayload,
      });
      const groupRes = response.data?.data;
      if (response.status === 200) {
        // Get the sorted group list
        const sortedGroups = getGroupsBySorting(
          groupRes.groups || groupRes,
          'updatedAt'
        );
        // Remove duplicates by 'id' using a Map
        const uniqueGroups = Array.from(
          new Map(sortedGroups.map(group => [group.id, group])).values()
        );
        // Set the data after filtering out duplicates
        setData(uniqueGroups);
        updateGroupType(null);
        return uniqueGroups;
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      return getGroupsBySorting(groupRes.groups || groupRes, 'updatedAt');
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getGroupsBySorting = (groupsData: Group[], sortBy: string) => {
    if (sortBy === 'name') {
      return groupsData.sort((a: Group, b: Group) =>
        a.name.localeCompare(b.name)
      );
    } else if (sortBy === 'createdAt') {
      return groupsData.sort(
        (a: Group, b: Group) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    } else {
      return groupsData.sort(
        (a: Group, b: Group) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
    }
  };

  return {
    loading,
    data,
    error,
    createGroup,
    updateGroup,
    deleteGroup,
    getGroupList,
    getGroupsBySorting,
  };
};

export default useGroupService;
