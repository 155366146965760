import React from 'react';
import { RingProgress } from '@mantine/core';

export interface CircularProgressProps {
  value: number;
  size?: number;
  thickness?: number;
  [key: string]: any; // This allows for any additional props
}

const CircularProgress: React.FC<CircularProgressProps> = ({
  value,
  size = 37,
  thickness = 3,
  ...others
}) => {
  return (
    <div className="flex justify-center items-center">
      <RingProgress
        sections={[{ value, color: 'green' }]}
        size={size}
        thickness={thickness}
        {...others}
      />
    </div>
  );
};

export default CircularProgress;
