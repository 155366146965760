import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAuthContext } from '../hooks/context/useAuthContext';
import { Document } from './DocumentContext';
// Define your Group type
interface Group {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

type GroupType = 'own' | 'shared' | 'all' | 'setting';

// Define context type
interface GroupContextType {
  group: Group | null;
  groups: Group[];
  setGroup: (_group: Group | null) => void;
  setGroups: (_group: Group[]) => void;
  setGroupType: (_group: GroupType | null) => void;
  updateGroupType: (_doument: Document | null) => void;
  isLoading: boolean;
  groupType: GroupType | null;
}

const GroupContext = createContext<GroupContextType>({
  group: null,
  groups: [],
  setGroup: (_group: Group | null) => {},
  setGroups: (_group: Group[]) => {},
  setGroupType: (_group: GroupType | null) => {},
  updateGroupType: (_doument: Document | null) => {},
  isLoading: false,
  groupType: 'own',
});

const leftMenuRouteMap: any = {
  '/groups': 'all',
  '/groups/all': 'all',
  '/groups/own': 'own',
  '/groups/shared': 'shared',
  '/group-management': 'groupManagement',
  '/role-management': 'roleManagement',
  '/': 'manageUsers',
  '/setting': 'setting',
};

// Group provider component
const GroupProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [group, setGroup] = useState<Group | null>(null);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupType, setGroupType] = useState<GroupType | null>(null);

  const { groupId } = useParams();
  const routePath = useLocation();
  const { userInfo } = useAuthContext();

  useEffect(() => {
    if (!groupId) {
      // check the path
      const groupType = leftMenuRouteMap[routePath.pathname];
      if (groupType) {
        setGroupType(groupType);
      } else if (userInfo?.isSuperAdmin) {
        setGroupType('all');
      }
    }
  }, [routePath.pathname]);

  // update group type based on document path viewd by user
  const updateGroupType = (documentInfo?: Document | null) => {
    if (documentInfo && !userInfo?.isSuperAdmin) {
      setGroupType(
        userInfo?.groupIds?.includes(groupId || '') ? 'own' : 'shared'
      );
    } else {
      const groupType = leftMenuRouteMap[routePath.pathname];
      setGroupType(groupType || (userInfo?.isSuperAdmin && 'all'));
    }
  };

  // Value object to pass into context provider
  const value: GroupContextType = {
    group,
    setGroup,
    groups,
    setGroups,
    isLoading,
    groupType,
    setGroupType,
    updateGroupType,
  };

  return (
    <GroupContext.Provider value={value}>{children}</GroupContext.Provider>
  );
};

export { GroupProvider, GroupContext, GroupContextType, Group };
export default GroupProvider;
