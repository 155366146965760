import React, { useState, useEffect, Fragment } from 'react';
import Styles from '../ManageRole.module.scss';
import { Button, Table, ColomnProps } from 'design-web';
import vectorBlueImg from '../../../assets/images/vector-blue.svg';
import searchImg from '../../../assets/images/search.svg';
import { useTheme } from 'shared/src/hooks/useTheme';
import trashRed from '../../../assets/images/trashRed.svg';
import Modal from 'packages/web/src/components/Modal';
import { RoleForm } from './RoleForm';
import useRoleService from 'shared/hooks/service/useRoleService';
import { useRoleContext } from 'shared/hooks/context/useRoleContext';
import { Role } from 'shared/contexts/RoleContext';
import useDebounce from 'shared/hooks/useDebounce';
import ShowMoreAddOns from 'packages/web/src/components/showMoreAddOns';
import DeleteModal from '../../../components/DeleteModal';
import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';
import noRecords from '../../../assets/images/noRecord.svg';
import UserActionMenu from 'packages/web/src/components/UserActionMenu';

export const RoleList: React.FC = () => {
  const { isMobile } = useTheme();
  const { roles: data, setRoles } = useRoleContext();
  const { getRoleList, loading, deleteRole } = useRoleService();
  // toggle delete modal
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  // set selected user
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { debounceVal, setCurrentValue } = useDebounce(null, 300);
  const [isModalVisibleForMobile, setIsModalVisibleForMobile] = useState(false);
  const [modalItems, setModalItems] = useState<string[]>([]);
  const [modalTitle, setModalTitle] = useState('');
  const [modalButtonClassName, setModalButtonClassName] = useState<string>('');

  const fetchData = async () => {
    try {
      const queryPayload = { searchTerm: debounceVal, pageSize: 1000 };
      const result = await getRoleList(queryPayload);
      setRoles(result || []);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [debounceVal]);

  const loadMoreRecords = () => {
    if (!loading) fetchData();
  };

  const handleDeleteRole = async () => {
    if (!selectedRole?.id) return;
    try {
      await deleteRole(selectedRole.id);
      setRoles(data.filter(item => item.id !== selectedRole.id));
      setIsDeleteModalVisible(false);
      setSelectedRole(null);
    } catch (error) {
      console.error(error);
    }
  };

  const columns: ColomnProps[] = [
    { key: 'role', title: 'User Role', textAlign: 'left' },
    { key: 'permission', title: 'Permissions', textAlign: 'left' },
    { key: 'action', title: 'Action', textAlign: 'left' },
  ];

  const handleAddClick = () => {
    setSelectedRole(null); // Reset selected user when adding a new role
    setIsModalVisible(true);
  };

  const handleEditClick = (role: Role) => {
    setSelectedRole(role); // Set selected user when editing
    setIsModalVisible(true);
  };

  const handleSearchRoles = (searchText: string) => {
    // fetch data with search term
    setCurrentValue(searchText);
  };

  const handleShowModal = (
    items: string[],
    title: string,
    buttonClassName: string
  ) => {
    setModalItems(items);
    setModalTitle(title);
    setModalButtonClassName(buttonClassName);
    setIsModalVisibleForMobile(true);
  };

  const tableData = data?.map((item, index) => ({
    role: (
      <div className="flex items-center py-2">
        {/* <img
          src={item.avatar}
          className="w-10 h-10 lg:w-12 lg:h-12 rounded-full"
          alt={item.name}
        /> */}
        <div className="max-w-xs">
          {/* Truncate text with ellipsis and show full text on hover */}
          <Button
            type="button"
            className={`text-white bg-primary-lightBlue hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-semiBase lg:text-base px-8 py-1 text-center me-2 mb-2 
            inline-block max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer`}
            title={item.name} // Show full text on hover
          >
            {item.name}
          </Button>
        </div>
      </div>
    ),

    permission: (
      <div className="flex flex-wrap gap-1">
        {item.rolePermission && item.rolePermission.length <= 2
          ? item.rolePermission.map((permissionObj, roleIndex) => (
              <span
                className="block text-black-text text-semiBase lg:text-base"
                key={roleIndex}
              >
                {permissionObj.permission.name}
                {roleIndex < item.rolePermission.length - 1 && ','}
              </span>
            ))
          : item.rolePermission?.slice(0, 2).map((permissionObj, roleIndex) => (
              <span
                className="block text-black-text text-semiBase lg:text-base"
                key={roleIndex}
              >
                {permissionObj.permission.name}
                {roleIndex < 1 && ','}
              </span>
            ))}

        {item.rolePermission.length > 2 && (
          <span
            className="block text-black-text text-semiBase cursor-pointer border ml-1 rounded-lg border-gray-300 bg-gray-100 px-1 hover:bg-gray-200"
            onClick={() =>
              handleShowModal(
                item.rolePermission.map(
                  permissionObj => permissionObj.permission.name
                ),
                item.name,
                'text-black-text text-semiBase lg:text-base border rounded-xl border-gray-300 py-1 px-2 bg-gray-100 hover:bg-gray-200 mb-2'
              )
            }
          >
            +{item.rolePermission.length - 2}
          </span>
        )}
      </div>
    ),
    action: (
      <UserActionMenu
        item={item as Role}
        handleEditClick={handleEditClick}
        setSelectedUser={setSelectedRole}
        setIsDeleteModalVisible={setIsDeleteModalVisible}
      />
    ),
  }));

  const AddRoleButton: React.FC<{
    className: string;
  }> = ({ className }) => (
    <Button type="button" className={className} onClick={handleAddClick}>
      <span
        className={`${isMobile ? 'text-xl' : 'text-base md:text-2xl'} mr-1`}
      >
        +
      </span>{' '}
      Add Roles
    </Button>
  );

  return (
    <>
      <div className="max-w-screen-xl mx-auto px-4 py-4 container">
        <div className="items-center justify-between md:flex">
          <div className="max-w-lg flex items-center justify-between">
            <h3 className="font-inter text-xl font-semibold leading-[29.26px] text-left mb-8 md:mb-0">
              Role Management
            </h3>
            {isMobile && (
              <AddRoleButton
                className="py-1 px-3 mb-8 text-semiBase md:text-xl font-bold
text-white focus:outline-none rounded-lg border
border-gray-200 bg-primary-lightBlue hover:bg-blue-500 focus:z-10 
focus:ring-4 focus:ring-gray-200 text-center"
              />
            )}
          </div>

          <div className="flex items-center">
            <div className="relative flex items-center w-full md:w-auto mr-0 sm:mr-4 mb-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <img src={`${searchImg}`} alt="Search image" className="" />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full text-[15px] font-normal text-left py-3 pl-10 px-6 text-gray-900 border 
        border-gray-300 rounded-xl bg-white focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search for Roles"
                onChange={e => handleSearchRoles(e.target.value)}
              />
            </div>
            {!isMobile && (
              <AddRoleButton
                className="py-2 px-10 mr-2 mb-2 text-semiBase md:text-xl font-normal
text-white focus:outline-none rounded-xl border
border-gray-200 bg-primary-lightBlue hover:bg-blue-500 focus:z-10 
focus:ring-4 focus:ring-gray-200 text-center"
              />
            )}
          </div>
        </div>
        <div className="shadow-md border rounded-2xl overflow-x-auto bg-white mt-4">
          {isMobile ? (
            <div className="flex flex-col">
              {loading ? ( // Conditionally show loader when loading is true
                <div className="flex justify-center items-center py-4">
                  {<DataSaveLoader visible={true} />}
                </div>
              ) : data.length === 0 ? ( // Check if there is no data
                <div className="flex justify-center items-center py-16">
                  <div>
                    <img
                      src={`${noRecords}`}
                      className="w-16 h-16"
                      alt="Empty state"
                    />
                    <p className="text-gray-500">No records</p>
                  </div>
                </div>
              ) : (
                <div>
                  {data.map((item, idx) => (
                    <div
                      key={idx}
                      className="bg-white shadow-md p-4 py-7 border"
                    >
                      <div className="flex items-center justify-between">
                        <div
                          className="flex items-center break-all"
                          style={{ maxWidth: '40%' }}
                        >
                          <button
                            type="button"
                            className={`text-white bg-primary-lightBlue hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-md px-2 py-2 text-center
                        inline-block max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer`}
                            title={item.name}
                          >
                            {item.name}
                          </button>
                        </div>
                        <div className="flex flex-wrap gap-1 px-2">
                          {item.rolePermission.length <= 1
                            ? item.rolePermission.map(
                                (permissionObj, roleIndex) => (
                                  <span
                                    className="block text-black-text text-semiBase lg:text-base"
                                    key={roleIndex}
                                  >
                                    {permissionObj.permission.name}
                                    {roleIndex <
                                      item.rolePermission.length - 1 && ','}
                                  </span>
                                )
                              )
                            : item.rolePermission
                                .slice(0, 1)
                                .map((permissionObj, roleIndex) => (
                                  <span
                                    className="block text-black-text text-semiBase lg:text-base"
                                    key={roleIndex}
                                  >
                                    {permissionObj.permission.name}
                                    {roleIndex < 1}{' '}
                                    {/* Only add a comma after the first item */}
                                  </span>
                                ))}

                          {item.rolePermission.length > 1 && (
                            <span
                              className="block text-black-text text-semiBase lg:text-base cursor-pointer border ml-1 rounded-lg border-gray-400 px-1 hover:bg-gray-200"
                              onClick={() =>
                                handleShowModal(
                                  item.rolePermission.map(
                                    permissionObj =>
                                      permissionObj.permission.name
                                  ),
                                  item.name,
                                  'text-black-text text-semiBase lg:text-base border rounded-xl border-gray-400 py-1 px-2 hover:bg-gray-100 mb-2'
                                )
                              }
                            >
                              +{item.rolePermission.length - 1}
                            </span>
                          )}
                        </div>

                        <div className="flex items-center">
                          <UserActionMenu
                            item={item as Role}
                            handleEditClick={handleEditClick}
                            setSelectedUser={setSelectedRole}
                            setIsDeleteModalVisible={setIsDeleteModalVisible}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className={`mx-auto mt-8 ${Styles.table}`}>
              <Table
                data={tableData}
                coloums={columns}
                exportAndSearchBar={false}
                loadingData={loading}
                // loadMoreRecords={loadMoreRecords}
                infiniteScrolling={true}
              />
            </div>
          )}
        </div>
      </div>

      {/* Modal for displaying for permissions */}
      <ShowMoreAddOns
        isOpen={isModalVisibleForMobile}
        onClose={() => setIsModalVisibleForMobile(false)}
        title={modalTitle}
        loading={loading}
        items={modalItems.map(item => ({ value: item, label: item }))}
        buttonClassName={modalButtonClassName}
      />

      {/* DeleteModal Component */}
      <DeleteModal
        title="Delete Role"
        isOpen={isDeleteModalVisible}
        loading={loading}
        onClose={() => setIsDeleteModalVisible(false)}
        onDelete={handleDeleteRole}
        body={'Are you sure you want to delete this role?'}
        primaryBtnTxt="Delete"
      />

      <RoleForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        editRole={selectedRole}
      />
    </>
  );
};
