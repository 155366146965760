import { PageLoader } from 'design-web';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';

const PrivateRoute: React.FC = () => {
  const { isAuthenticated, userInfo } = useAuthContext();
  const [isMounted, setIsMounted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => setIsMounted(true), 1000);
    if (!isAuthenticated) {
      // set current location in the session storate
      sessionStorage.setItem('previousRoute', JSON.stringify(location));
    } else {
      sessionStorage.removeItem('previousRoute');
    }
  }, []);
  return (
    isMounted &&
    (isAuthenticated ? (
      userInfo ? (
        <Outlet />
      ) : (
        <PageLoader />
      )
    ) : (
      <Navigate to="/login" state={{ from: location }} />
    ))
  );
};

export default PrivateRoute;
