import React, { useState, useEffect } from 'react';
import { Button, Dropdown, showToast } from 'design-web';
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import useRoleService from 'shared/hooks/service/useRoleService';
import Modal from 'packages/web/src/components/Modal';
import { useTheme } from 'shared/hooks/useTheme';
import Styles from '../ManageRole.module.scss';
import usePermissionService from 'shared/hooks/service/usePermissionService';
import { Role } from 'shared/contexts/RoleContext';
import { useRoleContext } from 'shared/hooks/context/useRoleContext';
import Select from 'react-select';
import { permissionCustomStyles } from '../../../components/MultiSelectDropdown';
import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';

interface RoleListProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editRole?: Role | null;
}

export const RoleForm: React.FC<RoleListProps> = ({
  isModalVisible,
  setIsModalVisible,
  editRole = null,
}) => {
  const { roles: data, setRoles } = useRoleContext();
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const { isMobile } = useTheme();
  const { createRole, updateRole, loading } = useRoleService();

  const { getPermissionsList, data: permissionData } = usePermissionService();

  useEffect(() => {
    const fetchRolesData = async () => {
      try {
        await getPermissionsList();
      } catch (error) {
        console.error(error);
      }
    };

    fetchRolesData();
  }, []);

  const isEditMode = !!editRole;
  const initialValues = isEditMode
    ? {
        name: editRole.name,
        permissionIds: editRole.rolePermission.map(permission => ({
          value: permission.permission.id,
          label: permission.permission.name,
        })),
      }
    : { name: '', permissionIds: [] };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Role is required'),
    permissionIds: Yup.array()
      .min(1, 'You must select at least one permission')
      .required('Permission is required'),
    // .matches(passwordValidationRegex, passwordValidationMessage),
  });

  const handleSubmit = async (
    values: any,
    actions: FormikHelpers<any>
  ): Promise<void> => {
    setIsSubmitInProgress(true);
    try {
      const mappedPermissionIds = values.permissionIds?.map(
        (permission: any) => permission.value
      );

      // Create a new values object without modifying the formik values directly.
      const formData = { ...values, permissionIds: mappedPermissionIds };

      if (isEditMode) {
        const result = await updateRole(editRole.id, formData); // Assume updateRole is the API call for updating the user
        if (result) {
          setRoles([
            ...data.map(item => (item.id === result.id ? result : item)),
          ]);
          showToast('success', 'Role Updated Successfully.');
        }
      } else {
        const result = await createRole(formData);
        if (result) {
          setRoles([result, ...data]); // Assuming data is the current list of roles
          showToast('success', 'Role Created Successfully.');
        }
      }
      setIsSubmitInProgress(false);
      console.log(values);
      actions.setSubmitting(false);
      setIsModalVisible(false);
    } catch (err: any) {
      setIsSubmitInProgress(false);
      actions.setSubmitting(false);
      const errorMessage =
        err?.data?.message ||
        'An error occurred while processing your request.';

      showToast('error', errorMessage); // Show error toast
    }
  };

  const CustomButton: React.FC<{
    type: 'submit' | 'button';
    className: string;
    onClick?: () => void;
    label: string;
    disabled?: boolean;
  }> = ({ type, className, onClick, label, disabled = false }) => (
    <Button
      type={type}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </Button>
  );

  return (
    <Modal
      title={isEditMode ? 'Edit Role' : 'Add New Roles'}
      loading={false}
      onClose={() => setIsModalVisible(false)}
      isOpen={isModalVisible}
      className={`max-w-xl px-10`}
    >
      {' '}
      {<DataSaveLoader visible={isSubmitInProgress} />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, dirty }) => (
          <Form>
            <>
              <div className="flex flex-col items-center w-full mt-4 gap-4 p-2 sm:p-1">
                <div className="w-full">
                  <label
                    htmlFor={`name`}
                    className="block text-semiBase sm:text-base font-semibold text-gray-700 font-inter leading-[19.5px] text-left"
                  >
                    User Role Name
                  </label>
                  <Field
                    type="text"
                    id={`name`}
                    name={`name`}
                    placeholder="Enter Role Name"
                    autoComplete="off"
                    className={`mt-1 p-2 py-3 border ${
                      errors.name && touched.name
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } rounded-xl w-full block mb-2 text-semiBase sm:text-base font-bold text-gray-500 font-inter text-left`}
                  />
                  <ErrorMessage
                    name={`name`}
                    component="div"
                    className="text-brandRed font-inter text-base font-medium leading-[19.5px] text-left mt-1"
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor={`permissionIds`}
                    className={`block text-semiBase sm:text-base font-semibold text-gray-700 font-inter leading-[19.5px] text-left`}
                  >
                    Permissions
                  </label>
                  <Select
                    isMulti
                    name={`permissionIds`}
                    options={permissionData.map(permission => ({
                      value: permission.id,
                      label: permission.name,
                    }))}
                    onChange={(option: any) =>
                      setFieldValue(`permissionIds`, option)
                    }
                    value={values.permissionIds}
                    placeholder="Select Permissions"
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    isSearchable={isMobile ? false : true}
                    className={`${
                      errors && errors?.permissionIds && touched?.permissionIds
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } text-semiBase sm:text-base font-medium text-gray-900 font-inter text-left`}
                    styles={permissionCustomStyles(
                      !!errors?.permissionIds && !!touched?.permissionIds
                    )}
                  />
                  <ErrorMessage
                    name={`permissionIds`}
                    component="div"
                    className="text-brandRed font-inter text-base font-medium leading-[19.5px] text-left mt-1"
                  />
                </div>
              </div>
            </>

            {isMobile && (
              <div className="mt-2 mb-14 mr-2 flex flex-col pt-12 gap-4">
                <CustomButton
                  type="submit"
                  className={`w-full py-2 whitespace-nowrap rounded-xl focus:outline-none focus:ring-2 text-lg font-normal leading-[30px] text-center 
                    ${
                      isEditMode && !dirty
                        ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                        : 'bg-primary-lightBlue text-white hover:bg-blue-500 focus:ring-blue-600'
                    }`}
                  label={isEditMode ? 'Save Changes' : 'Add Role'}
                  disabled={isEditMode && !dirty} // Disable button if no changes or form is invalid
                />
                <CustomButton
                  type="button"
                  className="w-full border border-primary rounded-xl py-2 bg-white text-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-gray-400 text-lg font-normal leading-[30px] text-center"
                  onClick={() => setIsModalVisible(false)}
                  label=" Cancel"
                />
              </div>
            )}
            {!isMobile && (
              <div className="w-full mt-2 mb-10 pt-10 pl-2 pr-1 mr-8 flex justify-end gap-4">
                <CustomButton
                  type="button"
                  className="border w-1/2 border-primary whitespace-nowrap rounded-xl py-2 bg-white text-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-gray-400 text-xl font-normal leading-[30px] text-center"
                  onClick={() => setIsModalVisible(false)}
                  label=" Cancel"
                />
                <CustomButton
                  type="submit"
                  className={`py-2 w-1/2 text-white whitespace-nowrap rounded-xl focus:outline-none focus:ring-2 text-xl font-normal leading-[30px] text-center
                    ${
                      isEditMode && !dirty
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-primary-lightBlue hover:bg-blue-500 focus:ring-blue-600'
                    }`}
                  label={isEditMode ? 'Save Changes' : 'Add Role'}
                  disabled={isEditMode && !dirty} // Disable button if no changes or form invalid
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
