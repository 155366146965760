import React from 'react';

interface FileFilledProps {
  fillColor: string;
  height: number;
  width: number;
  className?: string;
}

const FileFilled: React.FC<FileFilledProps> = ({
  fillColor,
  height,
  width,
  className,
}: FileFilledProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"
        fill={fillColor}
      />
      <path d="M13 2v7h7" fill={fillColor} />
    </svg>
  );
};

export default FileFilled;
