import { Stack } from '@mantine/core';
import { Typography } from './material';
import Modal from './Modal';

interface DialogBoxProps {
  showDialog: boolean | (() => void);
  cancelNavigation: any;
  confirmNavigation: any;
  setIsConfirmationModalVisible: (value: boolean) => void;
}

const ConfirmationDialog = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  setIsConfirmationModalVisible,
}: DialogBoxProps) => {
  return (
    <Modal
      title="Navigate Away..!!"
      loading={false}
      onClose={() => setIsConfirmationModalVisible(false)}
      isOpen={!!showDialog}
      className="max-w-md"
    >
      <div className="p-5">
        <Stack>
          <Typography variant="h5">There are some changes?</Typography>
          <Typography>Are you sure you want to navigate!!!!</Typography>
        </Stack>
        <div className="mt-8 flex items-center justify-end">
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={cancelNavigation}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary ltr:ml-4 rtl:mr-4"
            onClick={confirmNavigation}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;
