import { useContext } from 'react';
import {
  DocumentContext,
  DocumentContextType,
} from 'shared/contexts/DocumentContext';

const useDocumentContext = (): DocumentContextType => {
  const context = useContext(DocumentContext);
  if (!context) {
    throw new Error('useDocument must be used within an DocumentProvider');
  }
  return context;
};

export { useDocumentContext };
