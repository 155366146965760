import React, { useState } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

// create date range interface
interface DateRangeProps {
  onDateSelect: (newValue: DateValueType) => void;
  value?: DateValueType;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  inputFormat?: string;
  calendarIcon?: React.ReactNode;
  clearIcon?: React.ReactNode;
}

const DateRange: React.FC<DateRangeProps> = ({ onDateSelect }) => {
  const [value, setValue] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue: DateValueType) => {
    console.log('newValue:', newValue);
    setValue(newValue);
    onDateSelect(newValue);
  };

  return (
    <Datepicker
      showShortcuts={true}
      showFooter={false}
      value={value}
      onChange={handleValueChange}
      maxDate={new Date()} // Restrict future date selection
      classNames={{
        input: () =>
          'font-inter text-sm lg:text-sm placeholder:text-sm lg:placeholder:text-sm placeholder-gray-400 w-full pl-3 lg:pl-1 py-3 border border-gray-300 rounded-lg text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500',
      }}
      readOnly={true}
    />
  );
};
export default DateRange;
