import React from 'react';

interface UserMessageInputProps {
  message: string;
  onMessageChange: (message: string) => void;
}

const UserMessageInput: React.FC<UserMessageInputProps> = ({
  message,
  onMessageChange,
}) => (
  <div className="mb-4">
    <textarea
      value={message}
      onChange={e => onMessageChange(e.target.value)}
      placeholder="Add a message (optional)"
      className="w-full h-32 border-2 border-gray-500 rounded p-2 text-Base"
    />
  </div>
);

export default UserMessageInput;
