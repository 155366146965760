import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';
import React, { useEffect, useRef, useState } from 'react';
import { useDocumentContext } from 'shared/hooks/context/useDocumentContext';
import useFileUpload from 'shared/hooks/context/useFileUpload';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import useAccessControl from 'shared/hooks/useAccessControl';
import { ActionPermissions } from 'shared/types/types';
import { showToast } from 'design-web';
import CustomFileRender from './CustomFileRender';
interface PSPDFKitViewerProps {
  documentUrl: string;
  docName: string;
  docAction: string;
}

const PSPDFKitViewer: React.FC<PSPDFKitViewerProps> = ({
  documentUrl,
  docName,
  docAction = '',
}) => {
  const [loading, setLoading] = useState(false);

  const containerRef = useRef(null);
  const pdfDocInstance = useRef<any>(null);
  const currentDocLoadInProgress = useRef(false);
  const canDownload = useAccessControl(ActionPermissions.DOWNLOAD);

  const {
    document: doc,
    saveTriggered,
    setSaveDocTrigger,
  } = useDocumentContext();
  const { createDocumentVersion, uploadDocumentToS3, getDocumentDetail } =
    useDocumentService();

  const { getFileUploadUrl } = useFileUpload();
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  let PSPDFKit: any;

  const initializeDocAuth = async () => {
    if (window && window.DocAuth) {
      const docAuthSystem = await (window as any).DocAuth.createDocAuthSystem();
      const document = await docAuthSystem.importDOCX(await fetch(documentUrl));
      if (containerRef.current) {
        // load document in view mode
        const editor = await docAuthSystem.createEditor(containerRef.current, {
          document,
          __hidePDFDownload: true,
          __hideDOCXDownload: !canDownload,
        });
        pdfDocInstance.current = editor;
      }

      //  editor.setDisplayOptions(false)
    }
  };

  useEffect(() => {
    if (!documentUrl || loading || !docName) return;

    init();

    return () => {
      pdfDocInstance.current?.destroy && pdfDocInstance.current?.destroy();
      pdfDocInstance.current?.closeDocument &&
        pdfDocInstance.current?.closeDocument();
      if (containerRef?.current)
        PSPDFKit && PSPDFKit.unload && PSPDFKit.unload(containerRef?.current);
    };
  }, [documentUrl, docName]);

  const loadDocAuthScript = (callback: () => void) => {
    // check if script already exists in dom then do not load
    if (document.getElementById('belmont-docauth')) {
      callback();
      return;
    }
    // callback();
    // return;
    const script = document.createElement('script');
    script.src = `${window.location.protocol}//${window.location.host}/public/docAutherLib/docauth.js`; // Replace with your script's actual URL
    script.id = 'belmont-docauth';
    script.onload = () => callback();
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (loading) return;
    if (docAction === 'edit' && docName) {
      if (containerRef?.current)
        PSPDFKit && PSPDFKit.unload(containerRef?.current);
      init();
    }
  }, [docAction]);

  useEffect(() => {
    if (saveTriggered) {
      saveDoc();
    }
  }, [saveTriggered]);

  const saveDoc = async () => {
    if (!pdfDocInstance?.current) return;
    try {
      const document = doc;
      if (document) {
        setIsSubmitInProgress(true);
        // Save document to server
        const fileExtension = document.name.split('.').pop(); // Get the last part of the string after the last dot

        // fetch presigned url
        if (fileExtension) {
          const reqPayload = {
            fileName: document.name,
            documentId: document.id,
            documentUUID: document.documentUUID,
            groupId: document.groupId,
            contentType: 'application/octet-stream',
            parentId: document.parentId || undefined,
            isFolder: false,
            name: document.name,
          };
          const { data } = await getFileUploadUrl(reqPayload);
          console.log(data);

          // save document to server
          // Assuming the `editor` instance exists.
          let currentDoc;
          let docData;
          if (fileExtension === 'docx') {
            currentDoc = pdfDocInstance.current.currentDocument();

            // `doc` will be an `ArrayBuffer` containing the raw binary data of the DOCX.
            docData = await currentDoc.exportDOCX();
          } else {
            // export as pdf
            docData = await pdfDocInstance.current.exportPDF();
          }

          const response = await uploadDocumentToS3(docData, data.signedUrl);
          if (response.ok) {
            // create version of the document
            console.log('File uploaded successfully');
            await createDocumentVersion(doc.id, {
              ...reqPayload,
              versionUUID: data.versionUUID,
            });
            setIsSubmitInProgress(false);
            if (fileExtension === 'docx' || fileExtension === 'doc') {
              showToast('success', `Document updated successfully`);
            } else {
              showToast('success', `PDF updated successfully`);
            }
            // get document details
            await getDocumentDetail(reqPayload.groupId, reqPayload.documentId);
          } else {
            console.error('Error uploading file:', response.statusText);
          }
        }
      }
    } catch (err) {
      console.error('Error updating document:', err);
    } finally {
      setSaveDocTrigger(false);
    }
  };

  const init = async () => {
    if (currentDocLoadInProgress.current) return;
    currentDocLoadInProgress.current = true;
    if (docAction === 'edit' && docName?.endsWith('.docx')) {
      setLoading(true);
      loadDocAuthScript(async () => {
        try {
          if (window.DocAuth) {
            await initializeDocAuth();
            currentDocLoadInProgress.current = false;
            setLoading(false);
          }
        } catch (e) {
          console.error('Error initializing DocAuth:', e);
          currentDocLoadInProgress.current = false;
          setLoading(false);
        }
      });
    } else {
      currentDocLoadInProgress.current = false;
    }
  };

  // This div element will render the document to the DOM.
  return docAction === 'edit' && docName?.endsWith('.docx') ? (
    <div>
      <div
        ref={containerRef}
        style={{ width: '100%', height: '100vh' }}
        className="relative"
      />
      <DataSaveLoader visible={isSubmitInProgress || loading} />
    </div>
  ) : (
    documentUrl && (
      <DocViewer
        documents={[{ uri: documentUrl, fileType: docName.split('.').pop() }]}
        pluginRenderers={[CustomFileRender, ...DocViewerRenderers]}
        style={{ width: '100%', height: '80vh' }}
        prefetchMethod="GET"
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true,
          },
        }}
      />
    )
  );
};

export default React.memo(PSPDFKitViewer);
