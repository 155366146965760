import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import pencil from '../../assets/images/pencil.svg';
import { Button, showToast } from 'design-web';
import { useTheme } from 'shared/hooks/useTheme';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import { useState, useEffect } from 'react';
import ShowMoreAddOns from '../../components/showMoreAddOns';
import UserAvatar from '../documentManagement/components/share/components/UserAvatar';
import useUserService from 'shared/hooks/service/useUserService';
import DataSaveLoader from '../../components/DataSaveLoader';
import { getUserFullName } from 'shared/utils/utils';
import { User } from 'shared/contexts/UserContext';
import { Role } from 'shared/contexts/RoleContext';

interface ProfileFormValues {
  firstName: string;
  lastName: string;
  profileImg?: string | null;
}

const ProfileSettings = () => {
  const [modalItems, setModalItems] = useState<string[]>([]);
  const [profileImg, setProfileImg] = useState<string | null>(null); // Holds the uploaded profile image
  const [modalTitle, setModalTitle] = useState('');
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const [modalButtonClassName, setModalButtonClassName] = useState<string>('');
  const [isModalVisibleForMobile, setIsModalVisibleForMobile] = useState(false);
  const { isMobile } = useTheme();
  const { userInfo: user, isLoading } = useAuthContext();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const {
    updateUserProfile,
    loading,
    error,
    getPreSignedUrl,
    uploadImageToS3,
  } = useUserService(); // Destructure updateUser from useUserService

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Full Name is required'),
    lastName: Yup.string().nullable(),
  });

  const initialValues: ProfileFormValues = {
    firstName: user?.firstName || user?.email.split('@')[0] || '',
    lastName: user?.lastName || '',
    profileImg: user?.profileImg || null,
  };

  useEffect(() => {
    if (user?.profileImg) {
      setProfileImg(user.profileImg); // Set initial profile image from user info
    }
  }, [user]);

  // Handle Image Upload for Preview Only
  const handleImagePreview = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const file = event.target.files?.[0]; // Get the uploaded file
    if (file) {
      // Generate image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImg(reader.result as string); // Set the preview image
        setSelectedFile(file); // Save the file for later upload during submission
        setFieldValue('profileImg', reader.result); // Set the preview URL in Formik
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePencilClick = () => {
    document.getElementById('fileInput')?.click(); // Trigger hidden file input click
  };

  // Handle showing modal with role permissions
  const handleRoleClick = (role: Role) => {
    // Filter permissions based on role
    const rolePermissions =
      role.permissions?.map(permission => {
        return permission.name;
      }) || [];

    setModalItems(rolePermissions); // In a real scenario, filter these by the role.
    setModalTitle(`${role.name} Permissions`);
    setModalButtonClassName(
      'text-black-text text-semiBase lg:text-base border rounded-xl border-gray-300 py-1 px-2 bg-gray-100 hover:bg-gray-200 mb-2'
    );
    setIsModalVisibleForMobile(true);
  };

  const handleSubmit = async (values: ProfileFormValues, actions: any) => {
    setIsSubmitInProgress(true);
    try {
      let uploadedImageUrl = profileImg;
      // Check if a new image has been selected
      if (selectedFile) {
        // Get pre-signed URL for S3 upload
        const preSignedUrl = await getPreSignedUrl(
          selectedFile.name,
          selectedFile.type
        );
        if (!preSignedUrl) {
          throw new Error('Failed to retrieve pre-signed URL');
        }
        // Upload image to S3
        await uploadImageToS3(selectedFile, preSignedUrl);
        // Set the S3 URL as the uploaded image URL
        uploadedImageUrl = preSignedUrl.split('?')[0]; // Remove query params to get the actual file URL
      }

      // Create a payload, removing `profileImg` if it's null
      const payload = { ...values, profileImg: uploadedImageUrl };

      await updateUserProfile(user?.id || '', payload); // Call the updateUser API
      setIsSubmitInProgress(false);
      showToast('success', 'Profile updated successfully!');
    } catch (error: any) {
      console.log('errrrr', error);
      const errorMessage =
        error || 'An error occurred while processing your request.';
      showToast('error', errorMessage); // Show error toast
      setIsSubmitInProgress(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-50 py-8 px-4 w-full h-full">
      <div className="font-inter text-2xl font-semibold leading-[29.26px] self-start mb-4">
        Settings
      </div>
      <div className="flex sm:flex-row flex-col items-center justify-center bg-white rounded-2xl shadow-xl px-4 py-6 w-full max-w-6xl">
        {/* Profile Image and Info Section */}
        <div className="flex flex-col items-center justify-center w-full sm:w-[40%] h-full">
          {isMobile && (
            <div className="text-left mb-4 w-full">
              <h1 className="text-2xl font-semibold mb-3">Profile</h1>
              <hr className="border-t border-gray-300 w-full" />
            </div>
          )}
          <div className="relative mb-6">
            {profileImg ? (
              <img
                src={profileImg}
                alt={user?.name}
                className="w-60 h-60 rounded-full"
              />
            ) : (
              <UserAvatar
                email={user?.email || ''}
                name={getUserFullName(user as User) || 'U N'}
                size={'w-60 h-60'}
                initialsLength={2}
                fontSize={isMobile ? '60px' : '80px'}
              />
            )}
            <div
              className="border-2 border-white absolute bottom-5 right-5 bg-blue-500 p-2 rounded-full text-white cursor-pointer"
              onClick={handlePencilClick} // Click to trigger file upload
            >
              <img src={pencil} alt="pencil svg" />
            </div>
          </div>

          <div className="text-center space-y-4 sm:space-y-6">
            <h2 className="text-2xl font-semibold">
              {getUserFullName(user as User)}
            </h2>
            {!user?.isSuperAdmin && (
              <div className="text-center space-x-2 sm:space-x-3">
                {/* Display all roles */}
                {user?.roles?.map((role, roleIndex) => (
                  <button
                    key={role.id}
                    type="button"
                    className="text-white bg-primary-lightBlue hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-semiBase lg:text-xl px-6 py-1 text-center mb-1 sm:mb-2"
                    title={role.name}
                    onClick={() => handleRoleClick(role as Role)} // Click to show role permissions
                  >
                    {role.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Vertical Line Divider */}
        {!isMobile && (
          <div className="border-l border-gray-200 h-full mx-6"></div>
        )}

        {/* Formik Form Section */}
        <div className="w-full sm:w-[60%] px-8 py-2 pt-6 sm:pt-2">
          {!isMobile && (
            <>
              <h1 className="text-2xl font-semibold mb-2 sm:mb-4">Profile</h1>
              <hr />
            </>
          )}
          {<DataSaveLoader visible={isSubmitInProgress} />}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form className="space-y-4 sm:mt-8">
                {/* Hidden File Input for Image Upload */}
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  accept="image/*"
                  onChange={event => handleImagePreview(event, setFieldValue)} // Handle image upload
                />

                {/* Full Name */}
                <div className="sm:pt-1">
                  <label
                    htmlFor={`firstName`}
                    className="block text-semiBase sm:text-base font-semibold text-gray-700 font-inter leading-[19.5px] text-left"
                  >
                    Your First Name
                  </label>
                  <Field
                    name="firstName"
                    type="text"
                    placeholder="Your full name"
                    className={`mt-2 p-2 py-3 border ${
                      errors.firstName && touched.firstName
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } rounded-xl w-full block mb-2 text-semiBase sm:text-base font-bold text-gray-500 font-inter text-left`}
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-brandRed font-inter text-base font-medium leading-[19.5px] text-left mt-1"
                  />
                </div>

                {/* lastName */}
                <div className="pt-3">
                  <label
                    htmlFor="lastName"
                    className="block text-semiBase sm:text-base font-semibold text-gray-700 font-inter leading-[19.5px] text-left"
                  >
                    Your Last Name
                  </label>
                  <Field
                    name="lastName"
                    type="text"
                    placeholder="Your lastName"
                    className={`mt-2 p-2 py-3 border ${
                      errors.lastName && touched.lastName
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } rounded-xl w-full block mb-2 text-semiBase sm:text-base font-bold text-gray-500 font-inter text-left`}
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-brandRed font-inter text-base font-medium leading-[19.5px] text-left mt-1"
                  />
                </div>

                <div className="pt-3">
                  <label
                    htmlFor="email"
                    className="block text-semiBase sm:text-base font-semibold text-gray-700 font-inter leading-[19.5px] text-left"
                  >
                    Email Address
                  </label>
                  <input
                    type="text"
                    value={user?.email || ''}
                    readOnly
                    className="mt-2 p-2 py-3 border border-gray-300 rounded-xl w-full block mb-2 text-semiBase sm:text-base font-bold text-gray-500 font-inter text-left cursor-not-allowed"
                  />
                </div>

                {/* Save Button */}
                <div className="flex justify-center py-6">
                  <button
                    type="submit"
                    className={`py-2 px-2 w-full sm:w-1/2 text-white whitespace-nowrap rounded-xl bg-primary-lightBlue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 text-base sm:text-xl font-normal leading-[30px] text-center`}
                  >
                    {'Save Information'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          {/* Modal for displaying Roles or Groups */}
          <ShowMoreAddOns
            isOpen={isModalVisibleForMobile}
            onClose={() => setIsModalVisibleForMobile(false)}
            title={modalTitle}
            loading={isLoading}
            items={modalItems.map(item => ({ value: item, label: item }))}
            buttonClassName={modalButtonClassName}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
