import { useEffect, useState } from 'react';
import { useGroupContext } from 'shared/hooks/context/useGroupContext';
import useGroupService from 'shared/hooks/service/useGroupService';
import useDebounce from 'shared/hooks/useDebounce';
import searchImg from '../../../assets/images/search.svg';
import routes from 'shared/config/routes';
import Styles from '../ManageDocument.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getTransformedUrl } from 'packages/web/src/utils/utils';
import Loader from 'design-web/components/Loader/Loader';
import GroupFolder from '../../../assets/images/folder-group.svg';
import { useTheme } from 'shared/hooks/useTheme';
import EmptyState from '../../../components/EmptyState';

const GroupList = () => {
  // const { breadcrumb } = useBreadcrumb();
  const { isMobile } = useTheme();
  const { groups: groupListData, setGroups } = useGroupContext();
  const { getGroupList, loading, getGroupsBySorting } = useGroupService();
  const { debounceVal, setCurrentValue } = useDebounce(null, 300);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const routePath = useLocation();
  const { groupId, groupType } = useParams();

  const fetchData = async (page: number, loading = false) => {
    if (loading) return;
    const currentPage = page;
    const queryPayload = { searchTerm: debounceVal, groupType };
    try {
      const result = (await getGroupList(queryPayload)) || [];
      setGroups([...result]);
      setPage(currentPage + 1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(routePath.pathname, 'routepath');
    if (!groupId) {
      resetDocumentScreen();
      fetchData(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routePath.pathname]);

  useEffect(() => {
    if (debounceVal !== null) fetchData(page, loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceVal]);

  const handleSearchGroups = (searchText: string) => {
    resetDocumentScreen();
    // fetch data with search term
    setCurrentValue(searchText);
  };

  const handleGroupClick = (group: any) => {
    // navigate to specific group
    // navigate(routes.groupDocuments, {groupId: groupId})
    navigate(getTransformedUrl(routes.groupDocuments, { groupId: group.id }), {
      state: { groupName: group.name },
    });
  };

  // const breadcrumbItems = breadcrumb.map((item, index) => (
  //   <NavLink to={item.href} key={index}>
  //     {item.title}
  //   </NavLink>
  // ));

  const resetDocumentScreen = () => {
    setPage(1);
    setGroups([]);
  };

  const sortGroupsData = (event: any) => {
    setGroups([...getGroupsBySorting(groupListData, event.target.value)]);
  };

  return (
    <div
      className={`max-w-screen-xl mx-auto px-4 py-4 ${Styles['document-management-container']}`}
    >
      <div className="w-full"></div>
      <div className="items-center justify-between md:flex">
        <div className="max-w-lg flex items-center justify-between">
          <h3 className="font-montserrat text-xl font-semibold leading-[29.26px] text-left mb-8 md:mb-0">
            Group Listing
          </h3>
          {/* {isMobile && (
            <div className="relative inline-block w-auto mb-6">
              <select
                className="appearance-none bg-white border border-gray-300 text-black text-semiBase font-medium py-3 pl-5 pr-16 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full md:w-auto"
                onChange={sortGroupsData}
              >
                <option value="updatedAt">Last modified</option>
                <option value="name">Name</option>
                <option value="createdAt">Date created</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                <svg
                  className="w-6 h-6 text-blue-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          )} */}
        </div>
        <div className="flex items-center">
          <div className="relative flex items-center w-full md:w-auto mr-0 sm:mr-4 mb-2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <img src={`${searchImg}`} alt="Search image" className="" />
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full text-[15px] font-normal text-left py-3 pl-10 px-6 text-gray-900 border 
        border-gray-300 rounded-xl bg-white focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search for Groups"
              onChange={e => handleSearchGroups(e.target.value)}
            />
          </div>
          {/* {!isMobile && (
            <div className="relative inline-block w-auto mb-2">
              <select
                className="appearance-none bg-white border border-gray-300 text-black text-base font-medium py-3 pl-8 pr-20 rounded-full md:rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full md:w-auto"
                onChange={sortGroupsData}
              >
                <option value="updatedAt">Last modified</option>
                <option value="name">Name</option>
                <option value="createdAt">Date created</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                <svg
                  className="w-6 h-6 text-blue-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          )} */}
        </div>
      </div>
      <div
        className={`shadow-md border rounded-2xl overflow-x-auto bg-white mt-4 ${Styles['group-list-container']}`}
      >
        <h3 className="font-montserrat text-2xl font-semibold leading-[29.26px] text-left mb-8 ">
          Groups
        </h3>
        <div className={`${Styles['group-list-scroll-view']}`}>
          <div
            className={`grid grid-cols-3 md:grid-cols-4 gap-14 sm:gap-10 lg:grid-cols-5`}
          >
            {groupListData.map((group, index) => (
              <div onClick={() => handleGroupClick(group)} key={index}>
                <div className="flex flex-col items-center w-14 sm:w-28 gap-3 sm:gap-4">
                  {' '}
                  {/* Set a consistent width for the container */}
                  <img
                    src={`${GroupFolder}`}
                    alt="Folder image"
                    className="cursor-pointer"
                  />
                  {/* Truncate the group name with a title attribute for full view on hover */}
                  <span
                    className="font-inter text-semiBase sm:text-base font-medium leading-[16.94px] text-center truncate overflow-hidden w-full max-w-sm sm:max-w-md align-middle leading-normal"
                    title={group?.name} // Show full name on hover
                  >
                    {group.name}
                  </span>
                </div>
              </div>
            ))}
            {loading && (
              <div className="flex justify-center items-center mt-8 inset-x-0 absolute">
                <Loader />
              </div>
            )}
            {!groupListData.length && !loading && <EmptyState />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupList;
