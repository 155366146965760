import { LoadingOverlay } from '@mantine/core';
import Loader from 'design-web/components/Loader/Loader';
import React from 'react';

interface LoaderProps {
  visible: boolean;
  className?: string;
}
const DataSaveLoader: React.FC<LoaderProps> = ({
  visible = false,
  className,
}) => {
  return (
    <LoadingOverlay visible={visible} loaderProps={{ children: <Loader /> }} />
  );
};

export default DataSaveLoader;
