// third-party
import { useDropzone } from 'react-dropzone';

// project import
import RejectionFiles from './RejectionFiles';
import PlaceholderContent from './PlaceholderContent';
import FilesPreview from './FilesPreview';

// types
import { useLocation, useParams } from 'react-router-dom';
import {
  CustomFile,
  DropzopType,
  UploadMultiFileProps,
} from '../../../types/dropzone';
import { Button } from 'design-web';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import useFileUpload from 'shared/hooks/context/useFileUpload';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import AcceptFileModal from './AcceptFileModal';

const DropzoneWrapper = styled.div({
  outline: 'none',
  padding: '16px',
  borderRadius: '8px',
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
});

// ==============================|| UPLOAD - MULTIPLE FILE ||============================== //

// interface UploadedFile {
//   name: string;
//   file: File;
// }

const MultiFileUpload = ({
  error,
  showList = true,
  files,
  type,
  setFieldValue,
  sx,
  onUpload,
  ...other
}: UploadMultiFileProps) => {
  const [showActionBtn, setShowActionBtn] = useState(false);
  const { checkFileNames } = useDocumentService();
  const [duplicateFiles, setDuplicateFiles] = useState<CustomFile[]>([]); // Store duplicate file names
  const [isModalOpen, setModalOpen] = useState(false);
  const { groupId, folderId } = useParams<any>();
  const [duplicateFileResponse, setDuplicateFileResponse] = useState<any>(null); // Store the API response
  const {
    files: filesInStore,
    startUpload,
    isUploading,
    onRemoveFile: updateFilesStore,
    resetFileContext,
    duplicateFilesRes,
  } = useFileUpload();
  const location = useLocation();

  useEffect(() => {
    if (duplicateFiles.length > 0) {
      setModalOpen(true); // Open modal if there are duplicates
    }
  }, [duplicateFiles]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: true,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/msword': ['.doc'],
    },
    onDrop: async (acceptedFiles: CustomFile[]) => {
      setShowActionBtn(true);
      if (files && files.length) {
        const uniqueFiles = [];
        for (const aFile of acceptedFiles) {
          let fileExist = false;
          for (const fileObj of files) {
            if (aFile.path === fileObj.path) {
              fileExist = true;
            }
          }
          if (!fileExist) {
            uniqueFiles.push(aFile);
          }
          acceptedFiles = uniqueFiles;
        }
      }

      if (acceptedFiles && acceptedFiles.length) {
        // Extract file names from the accepted files array
        const fileNames = acceptedFiles.map(file => file.name ?? '');
        // Check for duplicate file names on the server or backend
        const duplicateFileResponse = await checkFileNames(
          fileNames,
          groupId ?? '',
          folderId
        );
        // Store the response in state
        setDuplicateFileResponse(duplicateFileResponse);
        // Get names of duplicate files from the response
        const duplicateFileNames = duplicateFileResponse.data.map(
          (file: any) => file.name
        );
        // Filter out duplicate files from the accepted files
        const uniqueFiles = acceptedFiles.filter(
          file => !duplicateFileNames.includes(file.name)
        );
        // Filter the accepted files that are duplicates
        const duplicateFiles = acceptedFiles.filter(file =>
          duplicateFileNames.includes(file.name)
        );

        if (duplicateFileNames.length > 0) {
          setDuplicateFiles(duplicateFiles); // Set duplicate files to be shown in modal
        }

        // If there are non-duplicate files, process them
        if (uniqueFiles.length > 0) {
          const validFiles = uniqueFiles
            .map(file => {
              try {
                return Object.assign(file, {
                  preview: URL.createObjectURL(file),
                });
              } catch (e) {
                console.error('Invalid file passed to createObjectURL:', file);
                return null; // Handle invalid files if needed
              }
            })
            .filter(Boolean);

          setFieldValue('files', [...validFiles]);
        }
      }
    },
  });

  const resolveDuplicateFiles = (updatedFiles: any) => {
    const updatedAcceptFiles: File[] = duplicateFiles
      .map((file, index) => {
        if (file instanceof File) {
          // Create a new File instance
          const newFile = new File(
            [file], // File content
            updatedFiles[index]?.name || file.name, // Use the resolved name or fallback to the original
            {
              type: file.type, // Maintain the type of the file
              lastModified: file.lastModified, // Keep the lastModified date
            }
          );
          // Add the path property manually
          (newFile as any).path = updatedFiles[index]?.name; // Set path if it exists, otherwise use file name
          // Assign the action to indicate what was done (e.g., 'replace')
          (newFile as any).action = updatedFiles[index]?.action || '';
          return newFile;
        } else {
          console.warn('Invalid file type:', file);
          return null;
        }
      })
      .filter((file): file is File => file !== null); // Filter out null values

    const getExtension = (fileName: string) => fileName.split('.').pop(); // Extract extension
    const getBaseName = (fileName: string) =>
      fileName.split('.').slice(0, -1).join('.'); // Extract base name

    // Update file names to remove unnecessary spaces in base names
    updatedAcceptFiles.forEach((file, index) => {
      const baseName = getBaseName(file.name).trim(); // Trim base name
      const extension = getExtension(file.name); // Get file extension
      const trimmedFileName = `${baseName}.${extension}`; // Combine trimmed name

      // Create a new file with the trimmed name to preserve metadata
      const trimmedFile = new File([file], trimmedFileName, {
        type: file.type,
        lastModified: file.lastModified,
      });

      // Maintain the custom properties
      (trimmedFile as any).path = (file as any).path;
      (trimmedFile as any).action = (file as any).action;

      // Update the array with the newly trimmed file
      updatedAcceptFiles[index] = trimmedFile;
    });

    setModalOpen(false);

    // Update the form field value with the new files
    setFieldValue('files', [...(files ?? []), ...updatedAcceptFiles]);
  };

  useEffect(() => {
    resetFileContext();
  }, []);

  useEffect(() => {
    setShowActionBtn(false);
    if (!isUploading) {
      setFieldValue('files', []);
    }
  }, [isUploading]);

  const onRemoveAll = () => {
    setFieldValue('files', null);
  };

  const onRemove = (file: File | string) => {
    const filteredItems = files && files.filter(_file => _file !== file);
    setFieldValue('files', filteredItems);
    // remove this file from the store
    updateFilesStore(file);
  };

  const onUploadAll = () => {
    startUpload(files);
    duplicateFilesRes(duplicateFileResponse);
    if (onUpload) {
      onUpload();
    }
  };

  const removeAllFiles = () => {
    onRemoveAll();
  };

  const handleCloseModal = () => {
    setFieldValue('files', null);
    closeResolve();
  };

  const closeResolve = () => {
    setModalOpen(false);
    setDuplicateFiles([]);
  };

  return (
    <>
      {/* <div className="w-full">
        {breadcrumbItems.length && (
          <BreadcrumbNav
            navItems={breadcrumb}
            className="font-inter text-sm font-bold text-gray-500 leading-[15px] text-left mb-6"
          />
        )}
      </div> */}
      <div
        // className="mt-12 ml-7 sm:ml-10 md:ml-11"
        style={{
          // width: '84%',
          alignItems: 'center',
          ...(type === DropzopType.standard && {
            width: 'auto',
            display: 'flex',
          }),
          ...sx,
        }}
      >
        {(showActionBtn || !files?.length) && (
          <>
            <div
              {...(type === DropzopType.standard && { alignItems: 'center' })}
            >
              <DropzoneWrapper
                {...getRootProps()}
                className="text-center bg-drag-background border-drag-border border-dashed border-2 rounded-lg p-6"
                style={{
                  ...(isDragActive && { opacity: 0.72 }),
                  ...((isDragReject || error) && {
                    color: 'error.main',
                    borderColor: 'error.light',
                    bgcolor: 'error.lighter',
                  }),
                }}
              >
                <input {...getInputProps()} />
                <PlaceholderContent type={type} />
              </DropzoneWrapper>
              {type === DropzopType.standard && files && files.length > 1 && (
                <Button variant="contained" onClick={onRemoveAll} className="">
                  Remove all
                </Button>
              )}
            </div>
            {fileRejections.length > 0 && (
              <RejectionFiles fileRejections={fileRejections} />
            )}
          </>
        )}
        {files && files.length > 0 && (
          <FilesPreview
            files={files}
            showList={showList}
            onRemove={onRemove}
            type={type}
          />
        )}
      </div>

      {type !== DropzopType.standard &&
        showActionBtn &&
        files &&
        files.length > 0 && (
          <div className="flex justify-center gap-x-2.5">
            <Button
              type="button"
              onClick={onUploadAll}
              className="py-2 w-40 mt-12 mb-4 text-white whitespace-nowrap rounded-xl bg-primary-lightBlue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 text-base sm:text-xl font-normal leading-[30px] text-center"
            >
              Upload files
            </Button>
            <Button
              type="button"
              onClick={removeAllFiles}
              className="py-2 w-40 mt-12 mb-4 text-white whitespace-nowrap rounded-xl bg-danger hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 text-base sm:text-xl font-normal leading-[30px] text-center btn-outline-danger"
            >
              Remove files
            </Button>
          </div>
        )}

      {isModalOpen && (
        <AcceptFileModal
          isOpen={isModalOpen}
          acceptFiles={duplicateFiles}
          onResolve={resolveDuplicateFiles}
          onClose={handleCloseModal}
          closeResolve={closeResolve}
          duplicateFileResponse={duplicateFileResponse}
        />
      )}
    </>
  );
};

export default MultiFileUpload;
