/* eslint-disable @typescript-eslint/no-unused-vars */
import APIs from '../../config/apis';
import { useState } from 'react';
import apiClient from '../../config/axiosConfig';
import { Role } from 'shared/contexts/RoleContext';
import { param } from 'express-validator';

const useRoleService = () => {
  const [data, setData] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const createRole = async (roleData: any): Promise<Role | null> => {
    try {
      if (!roleData) {
        setError('No role data provided');
        return null;
      }
      setLoading(true);
      const response = await apiClient({
        ...APIs.ROLE.CREATE,
        data: roleData,
      });
      const roleRes = response.data;
      if (response.status === 200) {
        setData([...data, roleRes]);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }

      return roleRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const updateRole = async (
    roleId: string,
    roleData: any
  ): Promise<Role | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.ROLE.UPDATE(roleId),
        data: roleData,
      });

      const roleRes = response.data;
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      console.log(data, 'data');
      const result = [
        ...data.map(item => (item.id === roleRes.id ? roleRes : item)),
      ];
      console.log(result, 'result');
      setData([...data.map(item => (item.id === roleRes.id ? roleRes : item))]);

      return roleRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const deleteRole = async (id: string): Promise<Role | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.ROLE.DELETE(id),
      });
      const roleRes = response.data?.data;
      if (response.status === 200) {
        setData(data.filter(item => item.id !== id));
      }
      return roleRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const getRoleList = async (
    queryPayload: any = { searchBy: '' }
  ): Promise<Role[] | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.ROLE.GET_ALL,
        params: queryPayload,
      });
      const roleRes = response.data?.data;
      if (response.status === 200) {
        setData(roleRes.roles);
        console.log(roleRes.roles);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return roleRes.roles;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    error,
    createRole,
    updateRole,
    deleteRole,
    getRoleList,
  };
};

export default useRoleService;
