import React from 'react';
import UserPermissionSelect from './UserPermissionSelect';
import { ActionPermissions, PermissionTypes } from 'shared/types/types';
import { SharedUser } from 'shared/hooks/service/useDocumentService';
import { useTheme } from 'shared/hooks/useTheme';
import { getUserFullName } from 'shared/utils/utils';
import UserProfileImage from 'packages/web/src/components/UserProfileImage';
import { Document } from 'shared/src/contexts/DocumentContext';
import useAccessControl from 'shared/hooks/useAccessControl';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';

const UserListItem: React.FC<{
  user: SharedUser;
  permission: PermissionTypes;
  onPermissionChange: (email: string, permission: PermissionTypes) => void;
  onRemoveAccess: (email: string) => void;
  document: Document;
}> = ({ user, permission, onPermissionChange, onRemoveAccess, document }) => {
  const { isMobile } = useTheme();
  const canEdit = useAccessControl(ActionPermissions.EDIT);
  const { userInfo } = useAuthContext();

  return (
    <div className="flex flex-wrap items-center mb-2 hover:bg-gray-200 py-2 my-2 sm:m-2 sm:p-2">
      <UserProfileImage
        user={user}
        imgClassName={'w-9 h-9 sm:w-11 sm:h-11 mr-2 sm:mr-3 rounded-full'}
        avatarClassName={'w-9 h-9 sm:w-11 sm:h-11'}
        initialsLength={2}
        fontSize={isMobile ? '13px' : '16px'}
      />
      <div className="flex-1 min-w-0">
        <p className="font-bold text-sm sm:text-base break-words">
          {user.name || 'User name'}
        </p>
        <p className="text-sm sm:text-base text-gray-500 break-words">
          {user.email}
        </p>
      </div>
      {(canEdit || userInfo?.id === document.user.id) && (
        <UserPermissionSelect
          id={user.userId}
          permission={permission}
          onChange={(id, permission) => {
            onPermissionChange(id, permission);
          }}
          document={document}
        />
      )}
    </div>
  );
};

export default UserListItem;
