import { getInitials, stringToColor } from '../../../../../utils/utils';
import React from 'react';

interface UserAvatarProps {
  email: string;
  name: string;
  size?: string;
  fontSize?: string; // Custom font size
  initialsLength?: number; // Custom number of initials characters
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  email,
  name,
  size = 'w-10 h-10',
  fontSize = '16px', // Default font size
  initialsLength = 1, // Default to 2 initials
}) => {
  return (
    <div
      className={`${size} rounded-full mr-2 sm:mr-3 flex items-center justify-center text-white font-bold flex-shrink-0`}
      style={{ backgroundColor: stringToColor(email), fontSize }} // Use custom font size from props
    >
      {getInitials(name || 'U N', initialsLength)}{' '}
      {/* Use custom number of initials */}
    </div>
  );
};

export default UserAvatar;
