import React from 'react';
import { useTheme } from 'shared/hooks/useTheme';

const BaseLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { theme, sidebar } = useTheme();

  const themeClass = [
    (sidebar && 'toggle-sidebar') || '',
    theme.menu,
    theme.layout,
    theme.rtlClass,
    'main-section',
    'antialiased',
    'relative',
    'font-inter',
    'text-sm',
    'font-normal',
  ];

  return <div className={`${themeClass.join(' ')}`}>{children}</div>;
};

export default BaseLayout;
