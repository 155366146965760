import React, { Fragment, ReactNode } from 'react';
import { Button, PageLoader } from 'design-web';
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  loading: boolean;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  loading = false,
  className,
}) => {
  if (!isOpen) return null;

  return (
    <div className={`max-w-screen-xl mx-auto`}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => onClose()}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto p-4 lg:p-0">
            <div className="flex min-h-full items-center justify-center text-center">
              <DialogPanel
                className={`w-full max-w-5xl transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all  ${className}`}
              >
                <div
                  className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5"
                  title={title}
                >
                  {title && (
                    <DialogTitle
                      as="h3"
                      className="text-[20px] font-semibold sm:text-2xl sm:font-semibold leading-6 text-gray-900 text-left truncate max-w-[70%] overflow-hidden whitespace-nowrap"
                      title={title} // This will show the full title on hover
                    >
                      {title}
                    </DialogTitle>
                  )}
                  <Button
                    type="button"
                    onClick={onClose}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </Button>
                </div>
                {children}
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* // show loader */}
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-gray-500 opacity-50 flex items-center justify-center">
          <PageLoader />
        </div>
      )}
    </div>
  );
};

export default Modal;
