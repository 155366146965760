// UserProfileImage.tsx

import React from 'react';
import { getUserFullName } from 'shared/utils/utils';
import UserAvatar from '../pages/documentManagement/components/share/components/UserAvatar';

interface UserProfileImageProps {
  user?: any;
  imgClassName?: string;
  avatarClassName?: string;
  initialsLength?: number;
  fontSize?: string;
  avatarUserName?: string;
}

const UserProfileImage: React.FC<UserProfileImageProps> = ({
  user,
  imgClassName,
  avatarClassName,
  initialsLength,
  fontSize,
  avatarUserName,
}) => {
  const displayName = user ? getUserFullName(user) : avatarUserName || 'U N';

  return user?.profileImg ? (
    <img src={user?.profileImg} alt={user?.name} className={imgClassName} />
  ) : (
    <UserAvatar
      email={user?.email}
      name={displayName}
      size={avatarClassName}
      initialsLength={initialsLength}
      fontSize={fontSize}
    />
  );
};

export default UserProfileImage;
