import { useContext } from 'react';
import { GroupContext, GroupContextType } from 'shared/contexts/GroupContext';

const useGroupContext = (): GroupContextType => {
  const context = useContext(GroupContext);
  if (!context) {
    throw new Error('useGroup must be used within an GroupProvider');
  }
  return context;
};

export { useGroupContext };
