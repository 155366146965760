// Import necessary modules and components
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import 'react-perfect-scrollbar/dist/css/styles.css';

import { Link, NavLinks, Image, Icon } from 'design-web';
import routes from 'shared/config/routes';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'shared/hooks/useTheme';

import IconCaretsDown from 'design-web/icons/IconCaretsDown';
// import variable scss file
import colorCodes from 'shared/assets/styles/colors.module.scss';

// import "packages/web/src/assets/css/sidebar.css"
import { StatusCodes } from 'http-status-codes';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import belmontLogo from '../assets/images/belmontLogo.svg';
import { useGroupContext } from 'shared/hooks/context/useGroupContext';

// Define navigation items
// const navItems = [{ name: 'User Management', route: routes.user, icon: 'Home' }];

// Define Sidebar component
const Sidebar: React.FC = () => {
  const { theme, sidebar, toggleSidebar } = useTheme();
  const { groupType } = useGroupContext();
  const [currentMenu, setCurrentMenu] = useState<string>('');
  const { userInfo: user } = useAuthContext();
  const location = useLocation();
  const { t } = useTranslation();
  const toggleMenu = (value: string) => {
    setCurrentMenu(oldValue => {
      return oldValue === value ? '' : value;
    });
  };
  const navigate = useNavigate();
  const [_activeItem, setActiveItem] = useState(null);

  const handleLogout = async () => {
    navigate(routes.logout);
  };

  const _handleOnClick = (item: any) => {
    if (item.name == 'Logout') {
      handleLogout();
    }
    handleNavItemClick(item);
  };

  const handleNavItemClick = (item: any) => {
    setCurrentMenu(item.path); // Update to track the currently active menu item
    if (item.isExternal) {
      window.open(item.href, '_blank');
    } else {
      navigate(item.path);
    }
  };

  useEffect(() => {
    console.log(location, 'ss');
    if (window.innerWidth < 1024 && sidebar) {
      toggleSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const commonNavMenues = [
    {
      label: 'All Groups',
      icon: 'Document',
      path: '/groups/all',
      type: 'all',
    },
  ];
  let navMenues = [
    {
      label: 'Manage Users',

      icon: 'Home',
      path: '/',
      type: 'manageUsers',
    },
    {
      label: 'Role Management',
      icon: 'Role',
      path: '/role-management',
      type: 'roleManagement',
    },
    {
      label: 'Manage Groups',
      icon: 'Group',
      path: '/group-management',
      type: 'groupManagement',
    },
    ...commonNavMenues,
    {
      label: 'Settings',
      icon: 'Setting',
      path: '/setting',
      type: 'setting',
    },
  ];

  if (!user?.isSuperAdmin) {
    navMenues = [
      ...commonNavMenues,
      {
        label: 'My Groups',

        icon: 'Document',
        path: '/groups/own',
        type: 'own',
      },
      {
        label: 'Shared Groups',

        icon: 'Document',
        path: '/groups/shared',
        type: 'shared',
      },
      {
        label: 'Settings',
        icon: 'Setting',
        path: '/setting',
        type: 'setting',
      },
    ];
  }

  return (
    <div>
      <nav
        className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300 `}
      >
        <div className="bg-white dark:bg-black h-full">
          <div className="flex justify-between items-center px-4 py-3">
            <NavLink to="/" className="main-logo flex items-center shrink-0">
              <img
                src={`${belmontLogo}`}
                alt="Belmont Logo"
                className={`OpenLogo`}
              />
            </NavLink>

            <button
              type="button"
              className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
              onClick={() => toggleSidebar()}
            >
              <IconCaretsDown className="m-auto rotate-90" />
            </button>
          </div>
          <PerfectScrollbar className="h-[calc(100vh-80px)] relative">
            <ul className="relative font-semibold space-y-0.5 p-4 py-0 h-full">
              {user && (
                <li className="nav-item">
                  <ul>
                    {navMenues.map((navMenu, navIndex) => (
                      <li
                        className={`nav-item h-16 flex items-center ${
                          navMenu.type === 'setting' ? ' absolute bottom-8' : ''
                        }`}
                        key={navIndex}
                      >
                        <NavLink
                          to={navMenu.path}
                          className={({ isActive }) =>
                            `group ${
                              groupType === navMenu.type ? 'active' : ''
                            }`
                          }
                          onClick={() => handleNavItemClick(navMenu)}
                        >
                          <div className="flex items-center text-base font-medium leading-tight text-left">
                            {navMenu.type !== 'setting' ? (
                              <div className="active-border h-16"></div>
                            ) : (
                              ''
                            )}
                            <Icon
                              name={navMenu.icon}
                              height={24}
                              width={24}
                              className="h-4 w-4"
                              isHovered={groupType === navMenu.type}
                              fillColor={colorCodes.colorLeftMenu}
                            />
                            <span className="ltr:pl-3 rtl:pr-3 nav-text">
                              {t(navMenu.label)}
                            </span>
                          </div>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              )}
            </ul>
          </PerfectScrollbar>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
