import React from 'react';
import { Center, Text, Image } from '@mantine/core';

interface EmptyStateProps {
  icon?: string;
  title?: string;
  description?: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  icon,
  title = 'No Data Available',
  description,
}) => {
  return (
    <Center m="auto" h="full" p="md">
      <div className="flex flex-col items-center space-y-4">
        <div
          className="mantine-datatable-empty-state m_4451eb3a mantine-Center-root"
          data-active="true"
        >
          <div className="mantine-datatable-empty-state-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12.983 8.978c3.955 -.182 7.017 -1.446 7.017 -2.978c0 -1.657 -3.582 -3 -8 -3c-1.661 0 -3.204 .19 -4.483 .515m-2.783 1.228c-.471 .382 -.734 .808 -.734 1.257c0 1.22 1.944 2.271 4.734 2.74"></path>
              <path d="M4 6v6c0 1.657 3.582 3 8 3c.986 0 1.93 -.067 2.802 -.19m3.187 -.82c1.251 -.53 2.011 -1.228 2.011 -1.99v-6"></path>
              <path d="M4 12v6c0 1.657 3.582 3 8 3c3.217 0 5.991 -.712 7.261 -1.74m.739 -3.26v-4"></path>
              <path d="M3 3l18 18"></path>
            </svg>
          </div>
          <div
            className="mantine-focus-auto m_b6d8b162 mantine-Text-root"
            data-size="sm"
          >
            No records to show
          </div>
        </div>
      </div>
    </Center>
  );
};

export default EmptyState;
