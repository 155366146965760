import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import emitter, {
  EVENT_AUTH_TOKEN,
  EVENT_AUTH_TOKEN_INVALID,
  EVENT_LOGOUT,
} from '../helpers/emitter';
import Storage from '../helpers/Storage';
import { useGetUserAction } from '../hooks/useUserData';
import { showToast } from 'design-web';
import { User } from './UserContext';
import usePermissionService, {
  Permission,
} from '../hooks/service/usePermissionService';
import { useNavigate } from 'react-router-dom';

// Define context type
interface AuthContextType {
  authToken: string | null;
  isAuthenticated: boolean;
  isInitialized: boolean;
  route: string | null;
  isLoading: boolean;
  loggedInAs?: string | null;
  emitToken: (_authToken: string, _route?: string) => void;
  emitLogout: (_authToken?: string, _route?: string) => void;
  userInfo?: User | null;
  permissionData: Permission[] | null;
}

// Create a context object with initial values
const AuthContext = createContext<AuthContextType>({
  authToken: null,
  isAuthenticated: false,
  isInitialized: false,
  isLoading: false,
  route: null,
  loggedInAs: 'microsoft',
  emitToken: (_authToken: string, _route?: string) => {},
  emitLogout: (_authToken?: string, _route?: string) => {},
  userInfo: null,
  permissionData: [],
});

// Auth provider component
const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loggedInAs, setLoggedInAs] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [route, setRoute] = useState<string | null>(null);
  const { getUserData } = useGetUserAction();
  const { getPermissionsList } = usePermissionService();
  const [userInfo, setUser] = useState<User | null>(null);
  const [permissionData, setPermissionData] = useState<Permission[] | null>(
    null
  );
  const navigate = useNavigate();

  const emitToken = useCallback(
    (authToken: string, route?: string, loggedInAs?: string) => {
      emitter.emit(EVENT_AUTH_TOKEN, {
        authToken,
        route,
        loggedInAs,
      });
    },
    []
  );

  const emitLogout = useCallback((authToken?: string, route?: string) => {
    emitter.emit(EVENT_LOGOUT, {
      authToken,
      route,
    });
  }, []);

  const onAuthTokenInvalid = useCallback(async () => {
    setAuthToken(null);
    setIsAuthenticated(false);
    setUser(null);
    await Storage.removeItem(Storage.AUTH_TOKEN);
    await Storage.removeItem(Storage.LOGGED_IN_AS);
  }, [setUser]);

  const onLogout = useCallback(async () => {
    onAuthTokenInvalid();
    setRoute(null);
    setIsAuthenticated(false);
    await Storage.clear();
  }, [onAuthTokenInvalid]);

  const onAuthToken = useCallback(
    async ({
      authToken,
      route,
      loggedInAs = null,
      resolve,
      redirectToPreviousRoute = false,
    }: any) => {
      await Storage.setItem(Storage.AUTH_TOKEN, authToken);
      await Storage.setItem(Storage.LOGGED_IN_AS, loggedInAs);
      setAuthToken(authToken);
      setIsAuthenticated(true);
      setLoggedInAs(loggedInAs);
      if (route) {
        setRoute(route);
      }

      if (redirectToPreviousRoute) {
        // Redirect to previous route
        // This will be implemented based on your application's navigation logic
        // get current location from session storage
        const previousRoute: any = JSON.parse(
          sessionStorage.getItem('previousRoute') || '{}'
        );
        if (previousRoute?.pathname) {
          navigate(previousRoute?.pathname);
        }
        setRoute('/');
      }

      // callback function used to have control over the execution flow
      if (resolve) {
        resolve();
      }
    },
    []
  );

  useEffect(() => {
    const validateToken = async () => {
      try {
        if (!userInfo && authToken) {
          setIsLoading(true);

          const data = await getUserData(authToken);
          // fetch application permissions
          const permissions = await getPermissionsList();
          setPermissionData(permissions);
          if (!data) {
            setIsLoading(false);
            onAuthTokenInvalid();
          }
          setUser(data);
          // Validate token here
          setIsLoading(false);
        }
      } catch (err: any) {
        setIsLoading(false);
        onAuthTokenInvalid();
        showToast('error', err?.response?.data?.message);
      }
    };

    validateToken();
  }, [authToken, onAuthTokenInvalid, userInfo]);

  useEffect(() => {
    emitter.addListener(EVENT_LOGOUT, onLogout);
    emitter.addListener(EVENT_AUTH_TOKEN_INVALID, onAuthTokenInvalid);
    emitter.addListener(EVENT_AUTH_TOKEN, onAuthToken);

    return () => {
      emitter.removeListener(EVENT_LOGOUT, onLogout);
      emitter.removeListener(EVENT_AUTH_TOKEN_INVALID, onAuthTokenInvalid);
      emitter.removeListener(EVENT_AUTH_TOKEN, onAuthToken);
    };
  }, [onAuthToken, onAuthTokenInvalid, onLogout]);

  useEffect(() => {
    const checkStorage = async () => {
      try {
        const myToken = await Storage.getItem(Storage.AUTH_TOKEN);
        const loggedInAs = await Storage.getItem(Storage.LOGGED_IN_AS);
        if (myToken) {
          setAuthToken(myToken);
          emitToken(myToken, '', loggedInAs || '');
          setIsInitialized(true);
          setLoggedInAs(loggedInAs);
          setIsAuthenticated(true);
        } else {
          console.log('No token found, user needs to log in.');
          setIsInitialized(true);
        }
      } catch (err) {
        console.error('Error checking storage:', err);
      }
    };

    checkStorage();
  }, [emitToken]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      authToken,
      isInitialized,
      isLoading,
      route,
      loggedInAs,
      emitToken,
      emitLogout,
      userInfo,
      permissionData,
    }),
    [
      isAuthenticated,
      authToken,
      isInitialized,
      isLoading,
      route,
      loggedInAs,
      emitToken,
      emitLogout,
      userInfo,
      permissionData,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider, AuthContextType };
export default AuthProvider;
