import APIs from '../../config/apis';
import { useState } from 'react';
import apiClient from '../../config/axiosConfig';

export interface Permission {
  id: string;
  name: string;
}

const usePermissionService = () => {
  const [data, setData] = useState<Permission[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getPermissionsList = async (): Promise<Permission[] | null> => {
    try {
      setLoading(true);
      const response = await apiClient({
        ...APIs.PERMISSION.GET_ALL,
      });
      const permissionRes = response.data || [];
      if (response.status === 200) {
        setData(permissionRes);
        console.log(permissionRes);
      }
      if (response.status === 400) {
        throw new Error(response.data.message);
      }
      return permissionRes;
    } catch (ex: any) {
      console.error(ex);
      setError(ex.message);
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    error,
    getPermissionsList,
  };
};

export default usePermissionService;
