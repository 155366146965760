import { DocumentVersion } from 'shared/contexts/DocumentContext';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { UserCard } from './UserCard';

// Grouped cards by date with collapsible heading
export const DocumentGroupedByDate: React.FC<{
  documentVersions: DocumentVersion[];
  activeDocVersion: string;
  onDocVersionView: (docVersion: DocumentVersion) => void;
  onDownload: (docVersion: DocumentVersion) => void;
  onDelete: (docVersion: DocumentVersion) => void;
}> = ({
  documentVersions,
  activeDocVersion,
  onDocVersionView,
  onDownload,
  onDelete,
}) => {
  const [collapsedDates, setCollapsedDates] = useState<{
    [key: string]: boolean;
  }>({});

  // Group document versions by date
  const groupedByDate = documentVersions.reduce(
    (acc: { [key: string]: DocumentVersion[] }, version) => {
      // Use only the date part to ensure correct grouping
      const dateKey = dayjs(version.createdAt).format('MMMM D, YYYY');
      if (!acc[dateKey]) acc[dateKey] = [];
      acc[dateKey].push(version);
      return acc;
    },
    {}
  );

  const toggleCollapse = (date: string) => {
    setCollapsedDates(prev => ({ ...prev, [date]: !prev[date] }));
  };

  return (
    <div>
      {Object.keys(groupedByDate).map(date => (
        <div key={date}>
          {/* Date Heading */}
          <div
            className="flex items-center justify-between cursor-pointer bg-gray-200 p-4 mb-2 rounded-md"
            onClick={() => toggleCollapse(date)}
          >
            <h3 className="text-lg font-semibold">{date}</h3>
            <span>{collapsedDates[date] ? '▼' : '▲'}</span>
          </div>
          {/* Show cards only if not collapsed */}
          {!collapsedDates[date] && (
            <div className="mt-2">
              {groupedByDate[date].map(docVersion => (
                <UserCard
                  key={docVersion.id}
                  docVersion={docVersion}
                  activeDocVersion={activeDocVersion}
                  onDocVersionView={onDocVersionView}
                  onDownload={onDownload}
                  onDelete={onDelete}
                />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
