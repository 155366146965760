export default {
  login: '/login',
  logout: '/logout',
  singup: '/signup',
  help: '/help',
  forgotpassword: '/forgotpassword',
  verifyaccount: '/verifyaccount',
  home: '/',
  usermanagement: '/user-management',
  groups: '/groups',
  allGroups: '/groups/:groupType',
  sharedGroups: '/groups/:groupType',
  myGroups: '/groups/:groupType',
  groupDocuments: '/groups/:groupId/documents',
  groupFoldersDocuments: '/groups/:groupId/folders/:folderId',
  uploadFolderDocuments: '/groups/:groupId/folders/:folderId/uploadDocuments',
  uploadGroupDocuments: '/groups/:groupId/uploadDocuments',
  groupDocumentView: '/groups/:groupId/docView/:docId',
  rolemanagement: 'role-management',
  groupmanagement: 'group-management',
  userGroups: '/users/:id/groups',
  profile: '/profile',
  setting: '/setting',
  termsConditions: '/terms-conditions',
  privacyPolicy: '/privacy-policy',
  refundTerm: '/refund-term',
} as const;
