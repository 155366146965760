import React from 'react';

interface StackProps {
  children: React.ReactNode;
  direction?: 'row' | 'column';
  spacing?: number;
  className?: string;
}

export const Stack: React.FC<StackProps> = ({
  children,
  direction = 'column',
  spacing = 2,
  className,
}) => {
  const directionClass = {
    row: 'flex flex-row',
    column: 'flex flex-col',
  };

  const spacingClass: any = {
    0: 'gap-0',
    1: 'gap-1',
    2: 'gap-2',
    3: 'gap-3',
    4: 'gap-4',
    5: 'gap-5',
  };

  return (
    <div
      className={`${directionClass[direction]} ${spacingClass[spacing]} ${className}`}
    >
      {children}
    </div>
  );
};
