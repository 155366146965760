import React, { useEffect, useState } from 'react';
import rightArrow from '../../assets/images/rightArrow.svg';
import leftArrow from '../../assets/images/leftArrow.svg';
import { BreadcrumbNav, Button, showToast } from 'design-web';
import { useTheme } from 'shared/hooks/useTheme';
import { DocumentGroupedByDate } from './components/DocumentGroupByDate';
import PSPDFKitViewer from './components/PSPDFKitViewer';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useAccessControl from 'shared/hooks/useAccessControl';
import { ActionPermissions, DocSharePermissions } from 'shared/types/types';
import { Navigate } from 'react-router-dom';
import routes from 'shared/config/routes';
import { getDocumentViewUrl, getTransformedUrl } from 'shared/utils/utils';
import useDocumentService from 'shared/hooks/service/useDocumentService';
import { useDocumentContext } from 'shared/hooks/context/useDocumentContext';
import ShareModal from '../documentManagement/components/ShareModal';
import { DocumentVersion } from 'shared/contexts/DocumentContext';
import DataSaveLoader from '../../components/DataSaveLoader';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';

const DocumentView: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const { isMobile, toggleSidebar: toggleDocViewSidebar } = useTheme();
  const [isDropdownOpen, setDropdownOpen] = useState(false); // State to manage the dropdown
  const [docVersion, setDocVersion] = useState<DocumentVersion | null>(null);
  const [reloadDocViewer, setReloadDocViewer] = useState(false);
  const { docId, groupId } = useParams();

  const documentUrl = getDocumentViewUrl(docId || '', 'view');
  const documentDownloadUrl = getDocumentViewUrl(docId || '');

  const {
    getDocumentDetail,
    downloadDocument,
    deleteDocumentVersion,
    getDocumentVersions,
  } = useDocumentService();

  const {
    document,
    setSaveDocTrigger,
    setDocument,
    documentVersion,
    setDocumentVersion,
  } = useDocumentContext();
  const { userInfo } = useAuthContext();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [canEdit, setCanEdit] = useState(
    useAccessControl(ActionPermissions.EDIT)
  );
  // Check if the user has permission to share
  const [canShare, setCanShare] = useState(
    useAccessControl(ActionPermissions.SHARE)
  );
  const canView = useAccessControl(ActionPermissions.VIEW);
  const [canDownload, setCanDownload] = useState(
    useAccessControl(ActionPermissions.DOWNLOAD)
  );
  // let canEdit = useAccessControl(ActionPermissions.EDIT);
  const [docAction, setDocAction] = useState(canEdit ? 'edit' : 'view'); // State to trigger document save to server
  // Check if document is not a .docx file
  const isNotDocx = document?.name && !document?.name.endsWith('.docx');

  // Step 1: First useEffect to fetch and set document details
  useEffect(() => {
    const fetchDocumentDetails = async () => {
      if (groupId && docId) {
        await getUpdatedDocumentDetail(docId);
      }
    };

    fetchDocumentDetails();
  }, [groupId, docId]);

  // Step 2: Second useEffect to fetch and sort versions based on the updated document
  useEffect(() => {
    const fetchVersionsAndSort = async () => {
      if (groupId && docId && document) {
        const fileMetaDataPath = document.fileMetaData?.path;

        // Fetch version list
        const versionList = await getDocumentVersions(docId);

        // Sort version list to place the current document at the top
        if (versionList && fileMetaDataPath) {
          const index = versionList.findIndex(
            (docVersion: DocumentVersion) =>
              docVersion.fileMetaData.path === fileMetaDataPath
          );

          if (index > -1) {
            const [currentVersion] = versionList.splice(index, 1);
            versionList.unshift(currentVersion);
          }

          setDocumentVersion(versionList);
        }
      }
      toggleDocViewSidebar(window.innerWidth >= 1024);
    };

    fetchVersionsAndSort();
  }, [groupId, docId, document]); // This now runs only after document is updated

  const isSharedGroup = () => {
    return (
      !userInfo?.groupIds.includes(groupId || '') && !userInfo?.isSuperAdmin
    );
  };

  useEffect(() => {
    if (!document) return;

    const isSharedDownload =
      !isSharedGroup() ||
      (isSharedGroup() && !!document?.DocumentShare?.[0]?.documentId);

    const isSharedAllowed =
      !isSharedGroup() ||
      (isSharedGroup() &&
        document?.DocumentShare?.[0]?.action === DocSharePermissions.EDITOR);

    const canUserEdit = canEdit || userInfo?.id === document?.userId;
    const canUserShare =
      (canShare || userInfo?.id === document?.userId) && isSharedAllowed;
    setCanShare(canUserShare);
    setCanEdit(canUserEdit);

    setCanDownload(
      (canDownload || userInfo?.id === document?.userId) && !!isSharedDownload
    );
    setDocAction(canUserEdit ? 'edit' : 'view');
  }, [document]);

  useEffect(() => {
    if (!document) return;
    // check if document is shared document then it should be editable only when role has edit permission and shared permission should also be editor
    if (
      document?.DocumentShare &&
      document?.DocumentShare[0]?.action === DocSharePermissions.VIEWER
    ) {
      setDocAction(
        document?.DocumentShare[0]?.action === DocSharePermissions.VIEWER
          ? 'view'
          : 'edit'
      );
    } else if (canEdit) {
      setDocAction('edit');
    }
    setReloadDocViewer(false);
  }, [document]);

  useEffect(() => {
    if (docAction !== 'edit') return;
    const handleBeforeUnload = (event: any) => {
      // Custom message that will be displayed in some browsers
      const message =
        'Are you sure you want to leave? You may have unsaved changes.';
      event.returnValue = message; // Standard way to set the message
      return message; // Some browsers still use this for the confirmation
    };
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Attach the beforeunload event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [docAction]);

  // if canView is false then navigate to home page
  if (!canView) {
    return (
      <Navigate
        to={getTransformedUrl(routes.allGroups, { groupType: 'all' })}
      />
    );
  }

  const CustomButton: React.FC<{
    type: 'submit' | 'button';
    className: string;
    onClick?: () => void;
    label: string;
    disabled?: boolean;
  }> = ({ type, className, onClick, label, disabled = false }) => (
    <Button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const openShareModal = () => {
    setShareModalOpen(true);
  };

  const closeShareModal = () => {
    setShareModalOpen(false);
  };

  const handleDocEdit = (action = 'edit') => {
    setDocAction(action);
  };

  const handleDocVersionView = (docVersion: DocumentVersion) => {
    // set doc view type. if version is not latest version then doc will be in view mode only
    setTimeout(() => {
      setDocAction(
        document?.fileMetaData.path === docVersion.fileMetaData.path
          ? 'edit'
          : ''
      );
      setDocVersion(docVersion);
      setReloadDocViewer(false);
    }, 0);
    setReloadDocViewer(true);
  };

  const handleDownload = (docVersion: DocumentVersion) => {
    if (docVersion) {
      console.log('Downloading version:', docVersion);
      const documentDownloadEndPoint = `${documentDownloadUrl}&version=${docVersion.id}`;
      window.open(documentDownloadEndPoint, '_blank');
    }
  };

  const handleDocVersionDelete = async (versionToDelete: DocumentVersion) => {
    try {
      // deleting version which is not active version
      setReloadDocViewer(true);
      await deleteDocumentVersion(
        versionToDelete.documentId,
        versionToDelete.id
      );

      showToast('success', `Document version restored successfully`);
      setDocument(null);
      // get latest document detail
      await getUpdatedDocumentDetail(versionToDelete.documentId);
      setReloadDocViewer(false);
    } catch (error) {
      console.error('Error deleting document version:', error);
    }
  };

  const getUpdatedDocumentDetail = async (docId: string) => {
    try {
      await getDocumentDetail('', docId);
    } catch (error) {
      console.error('Error getting updated document detail:', error);
      // navigate to previous screen
      navigate(
        getTransformedUrl(routes.groupDocuments, {
          groupId: groupId,
        })
      );
    }
  };

  return (
    <div
      className={`min-h-screen flex bg-gray-100 mx-auto p-0 sm:p-1 ${
        isMobile ? 'flex-col' : 'flex-row'
      }`}
    >
      {/* Main Content Area */}
      <div className={`flex-1 p-4 ${isMobile ? 'w-full' : 'p-8'}`}>
        <div
          className={`flex items-start justify-between mb-4 ${
            isMobile ? 'flex-col space-y-4' : 'flex-row'
          }`}
        >
          <div className={`flex flex-col ${isMobile ? 'w-full' : ''}`}>
            <div className={'flex items-center space-x-20'}>
              <BreadcrumbNav className="font-inter text-sm font-bold text-gray-500 leading-[15px] text-left mb-6" />
              {/* Warning for non-editable document */}
              <div className="mb-2">
                {!isMobile && isNotDocx && (
                  <div className="bg-yellow-100 border border-yellow-300 p-2 sm:p-3 rounded-md text-yellow-800 mb-2 sm:mb-5 font-bold">
                    This document format is not editable. Only .docx files are
                    supported for editing.
                  </div>
                )}
              </div>
            </div>
            <h1
              className="mt-2 text-xl md:text-2xl font-bold truncate max-w-xs sm:max-w-md overflow-hidden whitespace-nowrap"
              title={document?.name} // Show full name on hover
            >
              {document?.name}
            </h1>
          </div>
          {/* Warning for non-editable document */}
          {isMobile &&
            isNotDocx &&
            canEdit &&
            (docAction === 'edit' || docAction === 'save') && (
              <div
                className={`bg-yellow-100 border border-yellow-300 p-2 sm:p-3 rounded-md text-yellow-800 mb-2 sm:mb-8 font-bold`}
              >
                This document format is not editable. Only .docx files are
                supported for editing.
              </div>
            )}
          <div className="flex items-center space-x-2 ml-auto right-0 mb-8 sm:mb-0">
            {/* <CustomButton
                            type="button"
                            className="border px-4 py-1 rounded-lg hover:bg-blue-600 border-primary whitespace-nowrap rounded-xl bg-white text-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm lg:text-base font-normal leading-[30px] text-center"
                            label="Download"
                        /> */}
            {canEdit &&
            !isMobile &&
            document?.name?.endsWith('.docx') &&
            (docAction === 'edit' || docAction === 'save') ? (
              <CustomButton
                type="button"
                className="px-4 py-1 rounded-lg hover:bg-blue-600 text-white whitespace-nowrap rounded-xl bg-primary-lightBlue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 text-sm lg:text-base font-normal leading-[30px] text-center"
                label="Save"
                onClick={() => setSaveDocTrigger(true)}
              />
            ) : null}
            {/* {canShare ? (
              <CustomButton
                type="button"
                className="px-4 py-1 rounded-lg hover:bg-blue-600 text-white whitespace-nowrap rounded-xl bg-primary-lightBlue hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 text-sm lg:text-base font-normal leading-[30px] text-center"
                label={'Share'}
                onClick={openShareModal}
              />
            ) : null} */}
            <div className="relative pl-2">
              {!document?.action && (canShare || canDownload) ? (
                <CustomButton
                  type="button"
                  className="border px-4 py-1 rounded-lg hover:bg-blue-600 border-primary whitespace-nowrap rounded-xl bg-white text-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm lg:text-base font-normal leading-[30px] text-center z-10"
                  label="Action"
                  onClick={() => setDropdownOpen(!isDropdownOpen)}
                />
              ) : (
                ''
              )}

              {isDropdownOpen && (
                <div className="absolute right-0 mt-3 w-32 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                  {canShare ? (
                    <button
                      className="block w-full px-4 py-3 text-sm lg:text-base text-left text-primary hover:bg-primary hover:text-white border-b rounded-lg"
                      onClick={() => {
                        setDropdownOpen(false);
                        // Add logic for 'Edit'
                        openShareModal();
                      }}
                    >
                      Share
                    </button>
                  ) : (
                    ''
                  )}
                  {canDownload && document ? (
                    <button
                      className="block w-full px-4 py-3 text-sm lg:text-base text-left text-primary hover:bg-primary hover:text-white border-b rounded-lg"
                      onClick={() => {
                        setDropdownOpen(false);
                        // Add logic for 'Save as'
                        downloadDocument(document.id, {
                          action: 'download',
                          isFolder: document.isFolder,
                          name: document.name,
                        });
                      }}
                    >
                      Download
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="min-h-screen bg-white p-4 md:p-6 shadow-lg rounded-lg relative z-0">
          {/* Document content */}
          {!reloadDocViewer && document ? (
            <PSPDFKitViewer
              documentUrl={
                docVersion
                  ? `${documentUrl}&version=${docVersion.id}`
                  : documentUrl
              }
              docAction={docAction}
              docName={document?.name || searchParams.get('docName') || ''}
            />
          ) : (
            <DataSaveLoader visible={true} />
          )}
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`max-h-[118vh] overflow-y-auto ${
          isMobile ? 'w-full mt-4' : isSidebarOpen ? 'w-80 md:w-96' : 'w-20'
        } transition-width duration-300 ease-in-out bg-white shadow-lg overflow-hidden p-4 border-1 rounded-xl border-gray-300 ${
          isMobile ? '' : 'mt-6'
        }`}
      >
        {/* Sidebar Toggle Button */}
        {!isMobile && (
          <button
            onClick={toggleSidebar}
            className="text-gray-500 focus:outline-none"
          >
            {isSidebarOpen ? (
              <img src={rightArrow} alt="" className="p-2" />
            ) : (
              <img src={leftArrow} alt="" className="p-2" />
            )}
          </button>
        )}

        {/* Sidebar Content */}
        {/* <hr /> */}
        {(isSidebarOpen || isMobile) && (
          <div className="p-2 flex items-center justify-between">
            <h2
              className="text-lg md:text-lg font-semibold truncate max-w-sm overflow-hidden whitespace-nowrap"
              title={document?.name} // Show full name on hover
            >
              {document?.name}
            </h2>
            {/* {!isMobile && (
              <button
                onClick={toggleSidebar}
                className="text-gray-500 focus:outline-none"
              >
                {isSidebarOpen ? (
                  <img src={rightGo} alt="" className="p-2 pb-4" />
                ) : (
                  <img src={rightGo} alt="" className="p-2 pb-4" />
                )}
              </button>
            )} */}
          </div>
        )}

        {/* Users List */}
        <hr />
        {(isSidebarOpen || isMobile) && document && (
          <div className="max-w-full mx-auto mt-2">
            {/* Render DocumentGroupedByDate ONCE, passing all document versions */}
            <DocumentGroupedByDate
              documentVersions={documentVersion || []}
              activeDocVersion={
                docVersion?.fileMetaData?.path || document?.fileMetaData?.path
              }
              onDocVersionView={handleDocVersionView}
              onDownload={handleDownload} // Pass the handleDownload function directly
              onDelete={handleDocVersionDelete}
            />
          </div>
        )}
      </div>

      {/* Share Modal */}
      {isShareModalOpen && document && (
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={closeShareModal}
          title={`Share "${document.name}"`}
          loading={false}
          document={document}
        />
      )}
    </div>
  );
};

export default DocumentView;
