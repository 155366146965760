import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import routes from 'shared/config/routes';
import { Icon } from 'design-web';
import IconMenu from 'design-web/icons/IconMenu';
import { useUserContext } from 'shared/hooks/context/useUserContext';

import Dropdown from './Dropdown';
import Notifications from './Notifications';
import LanguageSelector from './LanguageSelector';
import { useTheme } from 'shared/hooks/useTheme';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import belmontLogo from '../../assets/images/belmontLogo.svg';
import { getUserFullName } from 'shared/utils/utils';
import { User } from 'shared/contexts/UserContext';
import UserProfileImage from '../../components/UserProfileImage';

const profileMenu = [
  { name: 'Setting', route: routes.setting, icon: 'Setting' },
  { name: 'Logout', route: routes.logout, icon: 'Logout' },
];

function Header() {
  const { theme, sidebar, toggleSidebar, isMobile } = useTheme();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const { t } = useTranslation();
  const { userInfo: user } = useAuthContext();
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    // if route is logout then reload
    if (route === routes.logout) {
      window.location.href = route;
    } else {
      navigate(route);
    }
  };
  return (
    <header className="z-40 bg-white">
      <div className="shadow-sm">
        <div className="relative bg-white flex  w-full items-center px-5 py-2.5 dark:bg-black">
          <div className="horizontal-logo flex lg:hidden justify-between items-center ltr:mr-2 rtl:ml-2">
            {!isMobile && (
              <>
                <Link to="/" className="main-logo flex items-center shrink-0">
                  <img
                    src={`${belmontLogo}`}
                    alt="Belmont Logo"
                    className={`w-40`}
                  />
                </Link>
                <button
                  type="button"
                  className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
                  onClick={() => {
                    toggleSidebar();
                  }}
                >
                  <IconMenu className="w-5 h-5" />
                </button>
              </>
            )}

            {isMobile && (
              <>
                <button
                  type="button"
                  className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
                  onClick={() => {
                    toggleSidebar();
                  }}
                >
                  <IconMenu className="w-5 h-5" />
                </button>
                <Link
                  to="/"
                  className="main-logo flex items-center shrink-0 pl-16"
                >
                  <img
                    src={`${belmontLogo}`}
                    alt="Belmont Logo"
                    className={`w-28`}
                  />
                </Link>
              </>
            )}
          </div>
          {/* <div className="text-xl font-bold"></div> */}
          <div className="flex justify-end flex-1 items-center space-x-4">
            {/* Language Dropdown */}
            {/* <Dropdown
              trigger={
                <button
                  onClick={() => setIsLanguageOpen(!isLanguageOpen)}
                  className="flex justify-center items-center w-8 h-8 bg-gray-100 rounded-full hover:bg-gray-200"
                >
                  <Icon
                    name="I18n"
                    height={20}
                    width={20}
                    isHovered={isLanguageOpen}
                  />
                </button>
              }
              isOpen={isLanguageOpen}
              setIsOpen={setIsLanguageOpen}
            >
              <LanguageSelector />
            </Dropdown> */}

            {/* Notification Dropdown */}
            {/* <Dropdown
              trigger={
                <button
                  onClick={() => setIsNotificationOpen(!isNotificationOpen)}
                  className="flex justify-center items-center w-8 h-8 bg-gray-100 rounded-full hover:bg-gray-200"
                >
                  <Icon
                    name="Notification"
                    height={20}
                    width={20}
                    isHovered={isNotificationOpen}
                  />
                </button>
              }
              isOpen={isNotificationOpen}
              setIsOpen={setIsNotificationOpen}
            >
              <Notifications />
            </Dropdown> */}

            {/* Profile Dropdown */}
            <Dropdown
              trigger={
                <button
                  onClick={() => setIsProfileOpen(!isProfileOpen)}
                  className="flex justify-center items-center w-8 h-8 rounded-full"
                >
                  <UserProfileImage
                    user={user as User}
                    imgClassName={'w-8 h-8 rounded-full'}
                    avatarClassName={'w-8 h-8'}
                    initialsLength={2}
                    fontSize={'14px'}
                  />
                  {/* <Icon
                    name="User"
                    height={20}
                    width={20}
                    isHovered={isProfileOpen}
                  /> */}
                </button>
              }
              isOpen={isProfileOpen}
              setIsOpen={setIsProfileOpen}
            >
              <ul className="text-dark dark:text-white-dark !py-0 w-[230px] font-semibold dark:text-white-light/90">
                <li>
                  <div className="flex items-center px-4 py-4">
                    <UserProfileImage
                      user={user as User}
                      imgClassName={'w-10 h-10 rounded-md mr-2'}
                      avatarClassName={'w-12 h-12'}
                      initialsLength={2}
                      fontSize={'16px'}
                    />
                    <div className="ltr:pl-2 rtl:pr-2 truncate">
                      <h4 className="text-base">
                        {getUserFullName(user as User)}
                      </h4>
                      <div
                        className="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white whitespace-nowrap text-ellipsis overflow-hidden"
                        title={user?.email}
                      >
                        {user?.email}
                      </div>
                    </div>
                  </div>
                </li>
                {profileMenu.map(item => (
                  <li
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleNavigate(item.route)}
                    key={item.route}
                  >
                    {t(item.name)}
                  </li>
                ))}
              </ul>
            </Dropdown>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
