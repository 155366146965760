import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from 'localization';

import App from './app/App';
import ErrorBoundary from './components/ErrorBoundary';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './styles.scss';
// Tailwind css
import './assets/css/tailwind.css';
import '@mantine/core/styles.layer.css';
import 'mantine-datatable/styles.layer.css';
import { MsalAuthProvider } from 'shared/src/contexts/MsalAuthContext';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<App />} />)
);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <ErrorBoundary>
      <MsalAuthProvider>
        <I18nextProvider i18n={i18n}>
          <RouterProvider router={router} />
        </I18nextProvider>
      </MsalAuthProvider>
    </ErrorBoundary>
  </StrictMode>
);
