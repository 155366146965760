import { useAuthContext } from './context/useAuthContext';
import { ALLOWED_PERMISSIONS } from 'belmont-constants';
import { Permission } from '../types/types';

const useAccessControl = (requiredPermission: Permission) => {
  const { userInfo } = useAuthContext();

  if (!userInfo) {
    return false;
  }

  const newUserPermissions: string[] = Array.from(
    userInfo.permissions.reduce((acc, permission) => {
      const mappedPermissions = ALLOWED_PERMISSIONS[permission] || [permission];
      mappedPermissions.forEach(p => acc.add(p)); // Add each permission to the Set
      return acc;
    }, new Set<string>()) // Start with an empty Set
  );

  return (
    userInfo.isSuperAdmin || newUserPermissions.includes(requiredPermission)
  );
};

export default useAccessControl;
