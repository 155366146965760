// CustomDropdown.tsx
import { Menu } from '@mantine/core';
import React from 'react';
import { useTheme } from 'shared/hooks/useTheme';

interface ActionItem {
  label: string;
  action: (e: Event) => void;
}

interface CustomDropdownProps {
  items: ActionItem[];
  trigger: React.ReactNode;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ items, trigger }) => {
  const { isMobile } = useTheme();
  return (
    <Menu
      trigger={'click'}
      position={isMobile ? 'bottom-end' : 'bottom-start'}
      offset={1}
    >
      <Menu.Target>{trigger}</Menu.Target>
      <Menu.Dropdown className="min-w-[100px] sm:min-w-[150px] border border-gray-200 absolute right-0 mt-2 bg-gray-50 rounded-md shadow-lg py-1">
        {items.map((item, index) => (
          <Menu.Item
            key={index}
            onClick={(e: any) => item.action(e)}
            className="py-2 px-4 text-semiBase border-b border-gray-200 font-bold text-black hover:bg-primary-lightBlue hover:text-white rounded-lg transition-colors"
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default CustomDropdown;
