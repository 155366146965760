import Select, { StylesConfig, GroupBase, components } from 'react-select';
import UserProfileImage from 'packages/web/src/components/UserProfileImage';

// Custom Option Component
export const CustomOption = (props: any) => {
  const { data, isFocused, isSelected } = props;

  return (
    <components.Option {...props}>
      <div className={`flex flex-wrap items-center`}>
        <UserProfileImage
          user={{
            email: props.data?.value || '',
            name: props.data?.label || '',
            profileImg: props.data?.profileImg,
          }}
          imgClassName={'w-10 h-10 rounded-full mr-2 sm:mr-3'}
          avatarUserName={props.data?.value.split('@')[0] || ''}
          avatarClassName={'w-10 h-10'}
          initialsLength={2}
          fontSize={'16px'}
        />
        <div className="flex-1 min-w-0">
          <p className="font-bold text-sm sm:text-base break-words">
            {data.label}
          </p>
          <p className="text-sm sm:text-base text-gray-500 break-words">
            {data.value}
          </p>
        </div>
      </div>
    </components.Option>
  );
};

// Custom MultiValue Component
export const CustomMultiValue = (props: any) => {
  return (
    <components.MultiValue {...props}>
      <div className="flex flex-wrap items-center">
        <UserProfileImage
          user={{
            email: props.data?.value || '',
            name: props.data?.label,
            profileImg: props.data?.profileImg,
          }}
          avatarUserName={props.data?.value.split('@')[0]}
          imgClassName={'w-5 h-5 rounded-full mr-2'}
          avatarClassName={'w-5 h-5'}
          initialsLength={1}
          fontSize={'12px'}
        />
        <span className="text-gray-600 font-bold text-semiBase">
          {props.data.label}
        </span>
      </div>
    </components.MultiValue>
  );
};

// Optional custom styles for react-select
export const customStyles: StylesConfig<any, true, GroupBase<any>> = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#ffffff',
    borderColor: state.isFocused ? '#007bff' : '#718096',
    borderWidth: '2px',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(0,123,255,.25)' : 'none',
    '&:hover': {
      borderColor: '#007bff',
    },
    borderRadius: '6px',
    minHeight: '50px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#6c757d',
    fontSize: '14px',
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#495057',
    fontSize: '14px',
    margin: '0',
    padding: '0',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#F7FAFC',
    borderRadius: '15px',
    border: '1px solid #718096',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 4px',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: '#495057',
    fontSize: '14px',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: '#495057',
    cursor: 'pointer',
    marginLeft: '4px',
    '&:hover': {
      backgroundColor: '#718096',
      color: '#fff',
      borderRadius: '50%',
      padding: '2px',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#007bff'
      : state.isFocused
      ? '#EDF2F7'
      : '#F7FAFC',
    color: state.isSelected ? '#fff' : '#495057',
    padding: '8px 12px',
    fontSize: '14px',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: state.isSelected ? '#007bff' : '#f8f9fa',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '4px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '0',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#007bff',
    '&:hover': {
      color: '#0056b3',
    },
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: '#6c757d',
    '&:hover': {
      color: '#495057',
    },
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 1000, // Ensure the dropdown is above the modal
  }),
};
