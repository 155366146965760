import React from 'react';
import CustomDropdown from './CustomDropdown'; // Assuming this is the correct path for your CustomDropdown component
import dotAction from '../assets/images/dotAction.svg'; // Assuming correct path

interface UserActionMenuProps {
  item: any;
  handleEditClick: (user: any) => void;
  setSelectedUser: React.Dispatch<React.SetStateAction<any | null>>;
  setIsDeleteModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserActionMenu: React.FC<UserActionMenuProps> = ({
  item,
  handleEditClick,
  setSelectedUser,
  setIsDeleteModalVisible,
}) => {
  const actionItems = [
    {
      label: 'Edit',
      action: () => handleEditClick(item), // Opens the edit modal with the passed user (item)
    },
    {
      label: 'Delete',
      action: () => {
        setSelectedUser(item); // Set user for deletion
        setIsDeleteModalVisible(true); // Show delete modal
      },
    },
  ];

  return (
    <div className="py-2">
      <CustomDropdown
        trigger={
          <span className="py-2 text-center cursor-pointer">
            <img src={dotAction} alt="Open actions" className="w-4 h-4" />
          </span>
        }
        items={actionItems}
      />
    </div>
  );
};

export default UserActionMenu;
