import React, { useState, useEffect } from 'react';
import { Button, showToast } from 'design-web';
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import useGroupService from 'shared/hooks/service/useGroupService';
import Modal from 'packages/web/src/components/Modal';
import { useTheme } from 'shared/hooks/useTheme';
import { Group } from 'shared/contexts/GroupContext';
import { useGroupContext } from 'shared/hooks/context/useGroupContext';
import DataSaveLoader from 'packages/web/src/components/DataSaveLoader';

interface GroupListProps {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editGroup?: Group | null;
}

export const GroupForm: React.FC<GroupListProps> = ({
  isModalVisible,
  setIsModalVisible,
  editGroup = null,
}) => {
  const { groups: data, setGroups } = useGroupContext();
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  const { isMobile } = useTheme();
  const { createGroup, updateGroup, loading } = useGroupService();

  const isEditMode = !!editGroup;
  const initialValues = isEditMode
    ? {
        name: editGroup.name,
      }
    : { name: '' };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Group Name is required'),
    // .matches(passwordValidationRegex, passwordValidationMessage),
  });

  const handleSubmit = async (
    values: any,
    actions: FormikHelpers<any>
  ): Promise<void> => {
    setIsSubmitInProgress(true);
    try {
      if (isEditMode) {
        const result = await updateGroup(editGroup.id, values); // Assume updateGroup is the API call for updating the user
        if (result) {
          setGroups([
            ...data.map(item => (item.id === result.id ? result : item)),
          ]);
          showToast('success', 'Group Updated Successfully.');
        }
      } else {
        const result = await createGroup(values);
        if (result) {
          setGroups([result, ...data]); // Assuming data is the current list of roles
          showToast('success', 'Group Created Successfully.');
        }
      }
      setIsSubmitInProgress(false);
      console.log(values);
      actions.setSubmitting(false);
      setIsModalVisible(false);
    } catch (err: any) {
      setIsSubmitInProgress(false);
      actions.setSubmitting(false);
      const errorMessage =
        err?.data?.message ||
        'An error occurred while processing your request.';

      showToast('error', errorMessage); // Show error toast
    }
  };

  const CustomButton: React.FC<{
    type: 'submit' | 'button';
    className: string;
    onClick?: () => void;
    label: string;
    disabled?: boolean;
  }> = ({ type, className, onClick, label, disabled = false }) => (
    <Button
      type={type}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </Button>
  );

  return (
    <Modal
      title={isEditMode ? 'Edit Group' : 'Add New Groups'}
      loading={false}
      onClose={() => setIsModalVisible(false)}
      isOpen={isModalVisible}
      className={`max-w-xl px-10`}
    >
      {' '}
      {<DataSaveLoader visible={isSubmitInProgress} />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, dirty }) => (
          <Form>
            <>
              <div className="flex flex-col items-center w-full mt-4 gap-4 p-2 sm:p-1">
                <div className="w-full">
                  <label
                    htmlFor={`name`}
                    className="block text-semiBase sm:text-base font-bold text-gray-700 font-inter leading-[31.5px] text-left"
                  >
                    {isEditMode ? 'Rename' : 'Group Name'}
                  </label>
                  <Field
                    type="text"
                    id={`name`}
                    name={`name`}
                    autoComplete="off"
                    placeholder="Write name of the Group"
                    className={`mt-1 p-2 py-3 border rounded-xl w-full block mb-2 text-semiBase sm:text-base font-bold text-gray-500 font-inter text-left ${
                      touched.name && errors.name
                        ? 'border-brandRed'
                        : 'border-gray-300'
                    }`}
                  />
                  <ErrorMessage
                    name={`name`}
                    component="div"
                    className="text-brandRed font-inter text-base font-medium leading-[19.5px] text-left mt-1"
                  />
                </div>
              </div>
            </>

            {isMobile && (
              <div className="mt-2 mb-14 mr-2 flex flex-col pt-4 gap-4">
                <CustomButton
                  type="submit"
                  className={`w-full py-2 whitespace-nowrap rounded-xl focus:outline-none focus:ring-2 text-lg font-bold leading-[30px] text-center
                    ${
                      isEditMode && !dirty
                        ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                        : 'bg-primary-lightBlue text-white hover:bg-blue-500 focus:ring-blue-600'
                    }`}
                  label={isEditMode ? 'Save Changes' : 'Add Group'}
                  disabled={isEditMode && !dirty} // Disable if in edit mode with no changes or form is invalid
                />
                <CustomButton
                  type="button"
                  className="w-full border border-primary rounded-xl py-2 bg-white text-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-gray-400 text-lg font-normal leading-[30px] text-center"
                  onClick={() => setIsModalVisible(false)}
                  label=" Cancel"
                />
              </div>
            )}
            {!isMobile && (
              <div className="w-full mt-2 mb-10 pt-10 pl-2 pr-1 mr-8 flex justify-end gap-4">
                <CustomButton
                  type="button"
                  className="border w-1/2 border-primary whitespace-nowrap rounded-xl py-2 bg-white text-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-gray-400 text-xl font-normal leading-[30px] text-center"
                  onClick={() => setIsModalVisible(false)}
                  label=" Cancel"
                />
                <CustomButton
                  type="submit"
                  className={`py-2 w-1/2 whitespace-nowrap rounded-xl focus:outline-none focus:ring-2 text-xl font-normal leading-[30px] text-center 
                    ${
                      isEditMode && !dirty
                        ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                        : 'bg-primary-lightBlue text-white hover:bg-blue-500 focus:ring-blue-600'
                    }`}
                  label={isEditMode ? 'Save Changes' : 'Create New Group'}
                  disabled={isEditMode && !dirty} // Disable if in edit mode with no changes or form is invalid
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
