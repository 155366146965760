import APIs from 'shared/config/apis';
import { BASE_URL } from '../config/axiosConfig';
import { User } from '../contexts/UserContext';

export const getAccessToken = (): string => {
  const JWTTokenStorage: any = localStorage.getItem('JWT_AUTH_TOKEN');

  return JWTTokenStorage;
};

export const getLoogedInAs = (): string => {
  const loggedInAs: any = localStorage.getItem('LOGGED_IN_AS');

  return loggedInAs;
};

export const getDocumentViewUrl = (docId: string, action = 'download') => {
  const documentDownloadEndPoint = APIs.DOCUMENT.DOWNLOAD(docId);
  const accessToken = getAccessToken();
  const loggedInAs = getLoogedInAs();
  const docUrl = `${BASE_URL}${documentDownloadEndPoint.url}?action=${action}&token=${accessToken}&x-logged-in-as=${loggedInAs}`;
  return docUrl;
};

export const getUserFullName = (user: User): string => {
  if (!user || !user.email) {
    return ''; // Return empty string if user or email is undefined or null
  }

  let { firstName, lastName } = user;
  // if both first and last names are empty then extract name from email
  if (!firstName && !lastName) {
    let emailParts = user.email.split('@');
    emailParts = emailParts[0].split('.');
    firstName = emailParts[0];
    lastName = emailParts[1];
  }

  // Return concatenated first and last names with a space in between
  // or return empty string if either first or last name is empty
  return `${firstName || ''} ${lastName || ''}`.trim();
};

export const getTransformedUrl = (template: string, variables: any = {}) => {
  // String with dynamic variables using a custom syntax, e.g., {{variable}}
  const regex = /:([^:/]*)/g;
  return template.replace(regex, (match, p1) => variables[p1.trim()] as string);
};
