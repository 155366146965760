/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useBlocker } from './useBlocker';

export function useCallbackPrompt(when: boolean): (boolean | (() => void))[] {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [watchNavigation, setWatchNavigation] = useState(when);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation: Location) => {
      // in if condition we are checking next location and current location are equals or not
      if (!confirmedNavigation && nextLocation.pathname !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, location]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
    setWatchNavigation(false);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation && !watchNavigation) {
      setTimeout(() => {
        navigate(lastLocation?.pathname);
      }, 100);

      // Clean-up state on confirmed navigation
      setConfirmedNavigation(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation, watchNavigation]);

  useEffect(() => {
    setWatchNavigation(when);
  }, [when]);

  useBlocker(handleBlockedNavigation, watchNavigation);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
