import { useEffect, useRef } from 'react';
import { useBlocker as navigatorBlocker } from 'react-router-dom';

export function useBlocker(blocker: any, when = true): void {
  const nextLocationRef = useRef({
    pathname: '',
    search: '',
    hash: '',
  });
  const navigator = navigatorBlocker(({ currentLocation, nextLocation }) => {
    nextLocationRef.current = nextLocation;
    return when && currentLocation.pathname !== nextLocation.pathname;
  });
  useEffect(() => {
    if (!when) return;
    // Block navigating elsewhere when data has been entered into the input
    blocker(nextLocationRef.current);
    // navigatorBlocker(
    //   ({ currentLocation, nextLocation }) => {
    //     blocker(nextLocation);
    //     return currentLocation.pathname !== nextLocation.pathname
    //   }

    // );
    //return unblock;
    // const unblock = navigator.block((tx: Transition) => {
    //   const autoUnblockingTx = {
    //     ...tx,
    //     retry() {
    //       unblock();
    //       tx.retry();
    //     },
    //   };

    //   blocker(autoUnblockingTx);
    // });

    // return unblock;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextLocationRef.current, navigator]);
}
