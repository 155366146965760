import React from 'react';

interface BoxProps {
  children: React.ReactNode;
  className?: string;
  display?: 'block' | 'flex' | 'grid' | 'inline-block' | 'inline-flex';
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  padding?: number | string;
  margin?: number | string;
  sx?: object; // for styled-components or emotion-js usage, replace with your own implementation if needed.
}

export const Box: React.FC<BoxProps> = ({
  children,
  className,
  display = 'block',
  flexDirection,
  justifyContent,
  alignItems,
  padding,
  margin,
  sx,
}) => {
  const classes = {
    flex: display === 'flex',
    'flex-col': flexDirection === 'column',
    'flex-row': flexDirection === 'row',
    'justify-start': justifyContent === 'start',
    'justify-end': justifyContent === 'end',
    'justify-center': justifyContent === 'center',
    'items-start': alignItems === 'start',
    'items-end': alignItems === 'end',
    'items-center': alignItems === 'center',
  };

  return (
    <div
      className={` ${classes.flex} ${classes['flex-col']} ${classes['flex-row']} ${classes['justify-start']} ${classes['justify-end']} ${classes['justify-center']} ${classes['items-start']} ${classes['items-end']} ${classes['items-center']} ${className}`}
      style={{
        padding: padding,
        margin: margin,
        ...sx,
      }}
    >
      {children}
    </div>
  );
};
