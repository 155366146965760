import axios, { AxiosResponse } from 'axios';
import _ from 'underscore';

export const BASE_URL = `${
  process.env.NX_PUBLIC_API_URL || 'http://localhost:3001'
}`;

const apiClient = async (inputData: any) => {
  const token = localStorage.getItem('JWT_AUTH_TOKEN');
  const loggedInAs = localStorage.getItem('LOGGED_IN_AS');
  const commonHeaders = {
    'X-Logged-In-As': loggedInAs,
    'Content-Type': 'application/json',
    ...(token && token.length > 0 ? { Authorization: `Bearer ${token}` } : {}),
  };
  inputData.headers = {
    ...commonHeaders,
    ...inputData.headers,
  };

  const requestObj = _.extend(
    {},
    {
      url: `${BASE_URL}${inputData.url}`,
      method: inputData.method,
    },
    _.omit(inputData, 'url', 'method')
  );
  try {
    const response: AxiosResponse<any> = await axios(requestObj);

    return response as any;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('JWT_AUTH_TOKEN');
      localStorage.removeItem('LOGGED_IN_AS');
      window.location.href = '/login';
    }
    throw error.response;
  }
};

export default apiClient;
