// ManageUser.tsx

import React, { useEffect, useState } from 'react';
import GroupProvider from 'shared/contexts/GroupContext';
import GroupList from './components/GroupList';
import { DocumentList } from './components';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import routes from 'shared/config/routes';
import DocumentProvider from 'shared/contexts/DocumentContext';
import { useAuthContext } from 'shared/hooks/context/useAuthContext';
import { PageLoader } from 'design-web';
import useAccessControl from 'shared/hooks/useAccessControl';
import { ActionPermissions } from 'shared/types/types';

const ManageDocument: React.FC = () => {
  // add logic to read the route path and based on that render the respective components
  // For example, if the route path is /document/list, render DocumentList, otherwise render GroupList
  const { userInfo: user } = useAuthContext();
  const { groupId } = useParams();
  const [loading, setLoading] = useState(true);
  const canView = useAccessControl(ActionPermissions.VIEW);

  useEffect(() => {
    console.log('get user ', user);
    if (user) setLoading(false);
  }, [user]);

  // if canView is false then navigate to home page
  // if (!canView) {
  //   return <Navigate to={routes.allGroups} />;
  // }

  return (
    <>
      {user && (
        <>
          {!groupId ? (
            <GroupList></GroupList>
          ) : (
            <DocumentProvider>
              <DocumentList></DocumentList>
            </DocumentProvider>
          )}
        </>
      )}
      {!user && <PageLoader />}
    </>
  );
};

export default ManageDocument;
