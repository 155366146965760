import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outline' | 'contained';
  title?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  className,
  type = 'button',
  disabled,
  variant = '',
  title,
}) => {
  const buttonClassName = `${className} ${variant}`;
  return (
    <button
      onClick={onClick}
      type={type}
      className={buttonClassName}
      disabled={disabled}
      title={title}
    >
      {children}
    </button>
  );
};

export default Button;
