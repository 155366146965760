import { Breadcrumbs, Anchor } from '@mantine/core';
import { NavLink } from 'react-router-dom';
import useBreadcrumb from 'shared/hooks/useBreadcrumbs';

interface navItems {
  title: string;
  href: string | null;
}

interface BreadcrumbNavProps {
  navItems?: navItems[] | null;
  className?: string;
}

const BreadcrumbNav: React.FC<BreadcrumbNavProps> = ({
  navItems = null,
  className,
}) => {
  const { breadcrumb } = useBreadcrumb();

  if (!navItems) {
    navItems = breadcrumb;
  }
  return (
    <Breadcrumbs separator=">" separatorMargin="xs" className={className}>
      {navItems.map((item, index) =>
        item.href ? (
          <NavLink
            to={item.href}
            key={index}
            className={`${
              index !== navItems.length - 1
                ? 'text-primary font-bold'
                : 'text-inherit'
            } inline-block max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer align-middle leading-normal`}
            title={item.title} // Show full title on hover
          >
            {item.title}
          </NavLink>
        ) : (
          <span
            key={index}
            className={`truncate-item inline-block max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis align-middle leading-normal`}
            title={item.title}
          >
            {item.title}
          </span>
        )
      )}
    </Breadcrumbs>
  );
};

export default BreadcrumbNav;
