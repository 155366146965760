import React from 'react';
import OutsideClickHandler from '../../components/OutsideClickHandler';

interface DropdownProps {
  children: React.ReactNode;
  trigger: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  trigger,
  isOpen,
  setIsOpen,
}) => {
  return (
    <div className="relative">
      <div>{trigger}</div>
      {isOpen && (
        <OutsideClickHandler onOutsideClick={() => setIsOpen(isOpen)}>
          <div className="absolute right-0 mt-2 bg-white rounded-md shadow-lg py-1">
            {children}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default Dropdown;
