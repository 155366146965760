import React from 'react';
import Modal from 'packages/web/src/components/Modal';
import Styles from '../pages/userManagement/ManageUser.module.scss';

interface ShowMoreAddOnsProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading: boolean;
  items: { value: string; label: string }[]; // Change to an array of objects with value and label
  buttonClassName: string;
  crossClassName?: string;
  onItemRemove?: (item: { value: string; label: string }) => void; // Optional item removal handler
}

const ShowMoreAddOns: React.FC<ShowMoreAddOnsProps> = ({
  isOpen,
  onClose,
  title,
  loading,
  items,
  buttonClassName,
  crossClassName,
  onItemRemove,
}) => {
  return (
    <Modal
      title={title}
      loading={loading}
      onClose={onClose}
      isOpen={isOpen}
      className="max-w-md"
    >
      <div className="flex flex-wrap max-h-[300px] overflow-y-auto">
        {items.map((item, idx) => (
          <div
            key={idx}
            className="p-1 text-semiBase sm:text-base flex items-center"
          >
            <button
              type="button"
              className={`${buttonClassName} flex`}
              title={item.label}
            >
              <span className="inline-block max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis cursor-pointer">
                {item.label}
              </span>
              {onItemRemove && (
                <button
                  type="button"
                  onClick={() => onItemRemove(item)}
                  className={crossClassName}
                >
                  ✕
                </button>
              )}
            </button>
          </div>
        ))}
      </div>

      <div className="mt-6 flex items-center justify-end">
        <button type="button" className="btn btn-primary" onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ShowMoreAddOns;
