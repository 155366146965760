import React from 'react';

interface FormHelperTextProps {
  error?: boolean;
  id?: string;
  children: React.ReactNode;
}

const FormHelperText: React.FC<FormHelperTextProps> = ({
  error,
  id,
  children,
}) => {
  return (
    <div className={`form-helper-text ${error ? 'error' : ''}`} id={id}>
      {children}
    </div>
  );
};

export default FormHelperText;
