// ManageUser.tsx

import React, { useState } from 'react';
import { RoleList } from './components/RoleList';
import RoleProvider from 'shared/src/contexts/RoleContext';

const ManageRole: React.FC = () => {
  return (
    <RoleProvider>
      {' '}
      <RoleList />
    </RoleProvider>
  );
};

export default ManageRole;
