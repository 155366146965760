import React from 'react';

interface TypographyProps {
  children: React.ReactNode;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'overline';
  color?: 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  className?: string;
}

export const Typography: React.FC<TypographyProps> = ({
  children,
  variant = 'body1',
  color = 'textPrimary',
  align = 'inherit',
  className,
}) => {
  const variantClass: any = {
    h1: 'text-6xl font-bold',
    h2: 'text-5xl font-bold',
    h3: 'text-4xl font-bold',
    h4: 'text-3xl font-bold',
    h5: 'text-2xl font-bold',
    h6: 'text-xl font-bold',
    body1: 'text-lg',
    body2: 'text-md',
    caption: 'text-sm',
    overline: 'text-xs',
  };

  const colorClass: any = {
    primary: 'text-blue-600',
    secondary: 'text-gray-600',
    textPrimary: 'text-gray-900',
    textSecondary: 'text-gray-600',
    error: 'text-red-600',
  };

  const alignClass: any = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
    justify: 'text-justify',
  };

  return (
    <p
      className={`${variantClass[variant]} ${colorClass[color]} ${alignClass[align]} ${className}`}
    >
      {children}
    </p>
  );
};
